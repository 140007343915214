import { Button, Empty, Layout, List, Menu, Sidebar, UserMenu, useCreate, useGetIdentity, useGetOne, useNotify, usePermissions, useStore } from "react-admin";
import { ReactQueryDevtools } from 'react-query/devtools'
import { AppBar } from 'react-admin';
import Box from '@mui/material/Box';
import BookIcon from '@mui/icons-material/Book';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import PeopleIcon from '@mui/icons-material/People';
import LabelIcon from '@mui/icons-material/Label';
import { BsBack, BsFillGearFill } from "react-icons/bs";
import {
  FaCircle,
  FaFileUpload,
  FaFlag,
  FaHistory,
  FaList,
  FaUpload,
} from "react-icons/fa";
import { BiArchive, BiArrowBack, BiExit, BiUpload } from "react-icons/bi";
import SwitchSubscriber from "./Ats/SwitchSubscriber";


import DocuverusLogo from "../assets/images/docuverus_logo.png";
import {
  Dashboard,
  DashboardCustomizeSharp,
  DashboardOutlined,
  Square,
} from "@mui/icons-material";
import { useParams } from "react-router";
import { JSONTree } from "react-json-tree";
import React, { useEffect } from "react";
import { ATS_DOCS_URL, SOFTWARE_VERSION } from "../config";
import { ImUpload2 } from "react-icons/im";
import { RiFileUploadFill } from "react-icons/ri";
import ListSubheader from "@mui/material/ListSubheader";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import SendIcon from "@mui/icons-material/Send";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import { useLocation } from "react-router-dom";
import MuiList from "@mui/material/List";
import { IoMdSettings } from "react-icons/io";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ArticleIcon from '@mui/icons-material/Article';
import DnsIcon from '@mui/icons-material/Dns';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { Button as MuiButton } from '@mui/material';

const MyUserMenu = () => {
  const [create, { isSuccess, data }] = useCreate();
  useEffect(() => {
    if (data) {
      localStorage.removeItem("auth");
      window.location.replace(
        window.location.protocol +
          "//" +
          window.location.host +
          window.location.href.split(
            window.location.protocol + "//" + window.location.host
          )[1]
      );
    }
  }, [data]);

  return (
    <UserMenu>
      {/* <Button
            sx={{ width: 140 }}
            startIcon={<BiExit />}
            onClick={() => {
              create("logout");
            }}
            label="Logout"
          /> */}
      <MuiButton
        sx={{ width: 140 }}
        startIcon={<BiExit />}
        onClick={() => {
          create("logout");
        }}
        size="small"
      >
        Logout
      </MuiButton>
    </UserMenu>
  );
};

export const MyAppBar = () => {
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  const { data } = useGetIdentity();
  const subscriberView: boolean = data?.subscriberView;
  return (
    <AppBar userMenu={<MyUserMenu />} color="primary">
      {/* <TitlePortal /> */}
      <img src={DocuverusLogo} alt="Docuverus" />
      &nbsp;{!subscriberView && <strong>{switchSubscriberObj?.label}</strong>}
      <Box flex="1" />
      <Box flex="1" />
      {!subscriberView && <SwitchSubscriber />}
    </AppBar>
  );
};

export const MyMenu = (props) => {
    const [open, setOpen] = React.useState(false);
    const [openSettngs, setOpenSettngs] = useStore('openSettings', false);
    const [openIntegrationSettings, setOpenIntegrationSettings] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const settingsonClickHandler = () => {
        setOpenSettngs(!openSettngs);
    };
    const integrationSettingsonClickHandler = () => {
        setOpenIntegrationSettings(!openIntegrationSettings);
    };
    const location = useLocation();
    const { data } = useGetIdentity();
    const [switchSubscriberObj, setSwitchSubscriberObj] = useStore('switchSubscriberName', { value: '', label: '', companyType: '' });
    // const [switchSubscriber, setSwitchSubscriber] = useStore('switchSubscriber');
    // const {permissions:switchSubscriber} = usePermissions();
    const switchSubscriber =localStorage.getItem("RaStore.switchSubscriber")
    const subscriberView: boolean = data?.subscriberView;

  const [create] = useCreate();
  const notify = useNotify();
  useEffect(() => {
    console.log(window.location.pathname.split("/")[1]);
    if (window.location.pathname.split("/")[1] == "ml") setOpen(true);
  }, [window.location]);
  //handled redirected back to the old system
  const onClickHandler = () => {
    create(
      "logout",
      {},
      {
        onSuccess: (res) => {
          localStorage?.removeItem("auth");
          window?.location?.replace(`${ATS_DOCS_URL}`);
        },
        onError: (error) => {
          notify("Error: Retrain process abort", {
            type: "warning",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
        },
      }
    );
  };

    // get company type Data
    const { data: subscriberThirdPartyMenu } = useGetOne(
      "subscriber-third-party-menu",
      {
        id: switchSubscriberObj?.value,
      },
      {
        refetchOnWindowFocus: false,
        enabled: switchSubscriberObj?.value ? true : false,
      }
    );

    return (
        <Menu {...props}>
            {!(switchSubscriber === "true") ? (location.pathname.split('/')[1] == 'ml') ? <Menu.Item to={'/ats'} primaryText="Dashboard" leftIcon={<Dashboard />} /> : <Menu.DashboardItem /> : null}
            {(switchSubscriber === "true" && subscriberView ) ? (location.pathname.split('/')[1] == 'ml') ? <Menu.Item to={'/ats'} primaryText="Dashboard" leftIcon={<Dashboard />} /> : <Menu.DashboardItem /> : null}
            {(switchSubscriber === "true" && !subscriberView) && <Menu.Item to={'/ats/subscriber/inbox/' + switchSubscriberObj?.value} primaryText="Dashboard" leftIcon={<Dashboard />} />}
            {(subscriberView || (switchSubscriber === "true")) && <Menu.Item to={"/Ats/subscriber/getarchivedcancelled/" + (switchSubscriberObj?.value || data?.subscriberid)} primaryText="Archived / Cancelled" leftIcon={<BiArchive />} />}
            {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to="/Ats/Admin/SubscriberUsageList" primaryText="Subscriber Usage List" leftIcon={<PeopleIcon />} />}
            {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to="/Ats/Admin/getloginactivitylogs" primaryText="Log report " leftIcon={<BookIcon />} />}
            {!(switchSubscriber === "true") && data?.userrole == "Docuverus Super Admin" && <MuiList dense disablePadding>
                <Menu.Item to={'/ml'} onClick={(e) => { e.preventDefault(); handleClick() }} primaryText="ML Prediction" style= {{ fontSize: '13.7px'  }} leftIcon={open ? <ExpandLess /> : <ExpandMore />} />
                {/* <ListItemButton onClick={handleClick}>
                    <ListItemIcon sx={{ minWidth: 40 }}>
                        <BsFillGearFill />
                    </ListItemIcon>
                    <ListItemText primary="ML Prediction" primaryTypographyProps={{ style: { fontSize: '13.7px' } }}/>
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton> */}
                <Collapse sx={{background: "#2cc0e414"}} in={open} timeout="auto" unmountOnExit>
                    <MuiList component="div" disablePadding sx={{marginLeft: "16px"}}>
                        <Menu.Item to="/ml/predictps" primaryText="Predict Paystub" leftIcon={<RiFileUploadFill size={11} />} style={{ fontSize: '10.7px' }}/>
                        <Menu.Item to="/ml/predictbs" primaryText="Predict Bank" leftIcon={<FaFileUpload size={11} />} style={{ fontSize: '10.7px' }}/>
                        <Menu.Item to="/ml/mlclassdetails" primaryText="List & Retrain" leftIcon={<FaList size={11} />} style={{ fontSize: '10.7px' }}/>
                        <Menu.Item to="/ml/getclassretrainlogs" primaryText="Retrain Logs" leftIcon={<FaHistory size={11} />} style={{ fontSize: '10.7px' }} />
                        <Menu.Item to="/ml/getFlaggedDocuments" primaryText="Flagged Documents" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }}/>
                        <Menu.Item to="/ml/getFlaggedSOCRData" primaryText="Flagged SOCR Data" leftIcon={<FaFlag size={11} />} style={{ fontSize: '10.7px' }}/>
                    </MuiList>
                </Collapse>
            </MuiList>}
            {((subscriberView || (switchSubscriber === "true")) && (data?.userrole !== "Agent" && data?.userrole !== "Manager")) && <MuiList dense disablePadding>
                <Menu.Item to={'/ats/settings'} onClick={(e) => { e.preventDefault(); settingsonClickHandler() }} primaryText="Settings" style= {{ fontSize: '13.7px'  }} leftIcon={openSettngs ? <ExpandLess /> : <ExpandMore />} />
                <Collapse sx={{background: "#2cc0e414"}} in={openSettngs} timeout="auto" unmountOnExit>
                    <MuiList component="div" disablePadding sx={{marginLeft: "10px"}}>
                      {data?.userrole === "Docuverus Super Admin" &&
                        <Menu.Item to={"/ats/settings/hierarchyLevel"} primaryText="Hierarchy Level" leftIcon={<AccountTreeIcon sx={{ fontSize: 11 }}/>} style={{ fontSize: '10.7px' }}/>
                      }
                        {switchSubscriberObj?.companyType === "Property Management" && (
                          <Menu.Item
                            to={"/ats/settings/propertySetup"}
                            primaryText="Property Setup"
                            leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                            style={{ fontSize: "10.7px" }}
                          />
                        )}
                        {switchSubscriberObj?.companyType === "Real Estate" && (
                          <Menu.Item
                            to={"/ats/settings/officeSetup"}
                            primaryText="Office Setup"
                            leftIcon={<MapsHomeWorkIcon sx={{ fontSize: 11 }} />}
                            style={{ fontSize: "10.7px" }}
                          />
                        )}
                        {subscriberThirdPartyMenu?.data &&
                          subscriberThirdPartyMenu?.data?.length > 0 && (
                            <>
                              <Menu.Item to={'/ats/settings/integrationSettings'} onClick={(e) => { e.preventDefault(); integrationSettingsonClickHandler() }} primaryText="Integration Settings" style= {{ fontSize: '13.7px'  }} leftIcon={openIntegrationSettings ? <ExpandLess /> : <ExpandMore />} />
                              <Collapse
                                sx={{ background: "#2cc0e414" }}
                                in={openIntegrationSettings}
                                timeout="auto"
                                unmountOnExit
                              >
                                <MuiList component="div" disablePadding sx={{marginLeft: "10px"}}>
                                  {subscriberThirdPartyMenu?.data &&
                                    subscriberThirdPartyMenu?.data?.length > 0 &&
                                    subscriberThirdPartyMenu?.data?.map((menus) => (
                                      <Menu.Item
                                        to={`/ats/settings/integrationSettings/${menus?.appName}`}
                                        primaryText={menus?.appName}
                                        leftIcon={<DnsIcon sx={{ fontSize: 11 }} />}
                                        style={{ fontSize: "10.7px" }}
                                        key={menus?.appID}
                                      />
                                    ))}
                                </MuiList>
                              </Collapse>
                            </>
                          )}
                    </MuiList>
                </Collapse>
            </MuiList>}

            {/* <Menu.Item to={"/ats/emailSettings"} primaryText="Settings" leftIcon={<IoMdSettings />} /> */}
            {/* <Menu.Item to={"/ats/emailSettings"} primaryText="Email Settings" leftIcon={<MailOutlineIcon />} /> */}
            {(!subscriberView && !(switchSubscriber === "true")) && <Menu.Item to={"/ats/manageSubscribers"} primaryText="Manage Subscribers" leftIcon={<ManageAccountsIcon />} />}
            <Menu.Item to={"#"} onClick={() => onClickHandler()} primaryText="Back to Old system" leftIcon={<BiArrowBack />} />
        </Menu>
    )
};

const MySidebar = (props) => (
  <Sidebar
    sx={{
      "& .RaSidebar-fixed": {
        backgroundColor: "#eeeeee",
      },
    }}
    {...props}
  />
);

const MyLayout = (props: any) => (
    <div>
        
  <Layout
    // sx={{'& .RaLayout-appFrame':{margin:0}}}
    sx={{ "& .RaLayout-appFrame": { marginTop: "50px" } }}
    {...props}
    appBar={MyAppBar}
    menu={MyMenu}
    sidebar={MySidebar}
    //   notification=""
  >
   
  </Layout>
  <footer
      style={{
        backgroundColor: "#f8f9fa",
        padding: "10px",
        position: "fixed",
        bottom: "0",
        width: "100%",
        zIndex: "100", // You can adjust the z-index if needed
        display: "flex",
        justifyContent: "space-between", // Align content to the left and right edges
        alignItems: "center", // Vertically center content
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <p style={{ fontSize: "12px", margin: 0 }}>
          Need help completing your application? Docuverus can be reached by
          phone at <b>856.886.8843</b> or by email at{" "}
          <b>support@docuverus.com</b>
        </p>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <p
          style={{
            fontSize: "12px",
            margin: 0,
            marginRight: "20px",
            textAlign: "right",
          }}
        >
          © 2024 Docuverus (version{SOFTWARE_VERSION} )
        </p>
      </div>
    </footer>
  </div>
);

export default MyLayout;
