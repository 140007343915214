import React, { createContext, useEffect } from 'react';
import '../../App.css';
import {
    ListBase,
    Pagination, SearchInput, useListContext,
} from 'react-admin';
import { ToggleButton, ToggleButtonGroup, } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { Card } from '@mui/material';
import FilterModelAdmin from './FilterModelAdmin';
import { DatagridTable } from './GetsubscriberinboxList';
import AtsDashboard from '../../AtsDashboard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { JSONTree } from 'react-json-tree';
function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // event.preventDefault();
    console.info('You clicked a breadcrumb.');
}
export const ToogleFilterInboxType = (props) => {

    const { setFilters, filterValues, displayedFilters } = useListContext();
    useEffect(() => {
        if (!filterValues.app_invitation_type) {
            setFilters({ 'app_invitation_type': "1" }, ['app_invitation_type'])
        }
    }, [])

    return (
        <>
            {/* <JSONTree data={filterValues}/> */}
            <ToggleButtonGroup
                sx={{ paddingBottom: 0.5, '& .Mui-selected': { fontWeight: 700 } }}
                size="small"
                value={filterValues.app_invitation_type}
                color="primary"
                exclusive
                onChange={(event: React.MouseEvent<HTMLElement>,
                    newAlignment: string) => {
                    if (newAlignment) {
                        setFilters({ 'app_invitation_type': newAlignment }, ['app_invitation_type'])
                    }
                }}
                aria-label="Inbox"
            >
                <ToggleButton value={"1"} aria-label="Application">
                    Application
                </ToggleButton>
                <ToggleButton value={"2"} aria-label="Docupload">
                    Docupload
                </ToggleButton>
                <ToggleButton value={"3"} aria-label="Docupload">
                    Guest Card
                </ToggleButton>
            </ToggleButtonGroup>
        </>)
};

export const FilterListInbox = (props) => {
    return <>
        {/* <Button size='large' variant='outlined' startIcon={} label='' onClick={() => { }} /> */}
        <IconButton sx={{
            border: '1px solid',
            borderRadius: ' 5px',
            padding: '6px',
            marginLeft: '-10px',
            marginBottom: '4.4px'
        }} size='medium' aria-label="delete">
            <FilterListIcon />
        </IconButton>
    </>
}

export const filters = [
    <ToogleFilterInboxType source="app_invitation_type" alwaysOn />,
    <SearchInput variant="outlined" sx={{ backgroundColor: '#fff' }} placeholder="Search" InputLabelProps={{
        shrink: true,
    }} source="q" alwaysOn />,
];

export default function BasicPopover() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton color='warning' style={{ color: '#F9BB04' }} size='small' aria-label="info" aria-describedby={id} onClick={handleClick}>
                <InfoIcon style={{ fontSize: '14' }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>This is the Rent to Income Ratio.</Typography>
            </Popover>
        </div>
    );
}

const TopMenu = (props) => {
    return (<>
        <div className="app-head-row mb-2">
            <FilterModelAdmin />
        </div>
    </>)
}
//Avi
export const MyList = (props) => {
    const { children, title } = props;
    const mylistTempDefault = props.mylistTempDefault=='deactive' ?false : true
    return (
        <ListBase perPage={100}  {...props}>
            {/* <Header /> */}
            <br />
            <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography sx={{ fontWeight: 'bold' }} color="text.primary"> {title || 'Dashboard'}</Typography>
                </Breadcrumbs>
            </div>
            <br />
            
            {mylistTempDefault && <><TopMenu />
                <AtsDashboard isAdmin={true} /></>}
            <Card>
                {children}
            </Card>
            <Pagination  rowsPerPageOptions={[100,200,300,400,500]} />
            <br/><br/>
        </ListBase>
    )
};

export const GetAdmininboxList = () => {
    // Info : dahbaordOptions 
    // 1 - QA review Queue ,2- ATS, 3 - Docupload
    // 
    // const defaultFilter = dashboardOption == 1 ? 2 : dashboardOption == 2 ? 1 : 2;
    const defaultFilterValue = { app_invitation_type: 2, dashboardOption: 1 }
    return (
        // <DashboardOption.Provider value={dashboardOption}>
        <MyList toreKey="GetAdmininboxList" sort={{ field: 'id', order: 'DESC' }} actions={null} filter={defaultFilterValue} filterDefaultValues={defaultFilterValue} exporter={false} title={'Inbox'}>

            <DatagridTable queuedFieldDisplay={true} typeField={true} />
        </MyList>
        // </DashboardOption.Provider>
    )
};

export const GetAdmininboxList30Ats = () => {
    // Info : dahbaordOptions 
    // 1 - QA review Queue ,2- ATS, 3 - Docupload
    // 
    // const defaultFilter = dashboardOption == 1 ? 2 : dashboardOption == 2 ? 1 : 2;
    const defaultFilterValue1 = { app_invitation_type: 1, dashboardOption: 1 }
    return (
        // <DashboardOption.Provider value={dashboardOption}>
        <MyList toreKey="GetAdmininboxList30Ats" sort={{ field: 'id', order: 'DESC' }} actions={null} filter={defaultFilterValue1} filterDefaultValues={defaultFilterValue1} exporter={false} title={'Inbox'}>

            <DatagridTable typeField={false} screeningReport={true}/>
        </MyList>
        // </DashboardOption.Provider>
    )
};

export const GetAdmininboxList30doc = () => {
    // Info : dahbaordOptions 
    // 1 - QA review Queue ,2- ATS, 3 - Docupload
    // 
    // const defaultFilter = dashboardOption == 1 ? 2 : dashboardOption == 2 ? 1 : 2;
    const defaultFilterValue = { app_invitation_type: 2, dashboardOption: 1 }
    return (
        // <DashboardOption.Provider value={dashboardOption}>
        <MyList storeKey="GetAdmininboxList30doc" sort={{ field: 'id', order: 'DESC' }} actions={null} filterDefaultValues={defaultFilterValue} filter={defaultFilterValue} exporter={false} title={'Inbox'}>

            <DatagridTable typeField={false} />
        </MyList>
        // </DashboardOption.Provider>
    )
};

