import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import { BiTrash } from "react-icons/bi";
import { BsFillFileEarmarkPdfFill } from "react-icons/bs";
import { useSelector } from "react-redux";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { RiInformationLine } from "react-icons/ri";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

import {
  AccordionDetails,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Alert,
} from "@mui/material";
import { ErrorMessage } from "@hookform/error-message";
import CustomRadioButton from "../../common/InputComponents/CustomRadioButton";
import MaskInputField from "../components/inputComponents/MaskInputField";
import NoDocReasonRadioButtons from "../../common/InputComponents/NoDocReasonRadioButtons";
import InputField2 from "../../common/InputComponents/InputField2";
import DragDropDocumentButton from "../components/DragDropDocumentButton";
import AlertDialog from "../../common/AlertDialog";
import FormInstruction from "../../common/FormInstruction";
import FilePreview from "../../common/FilePreview";
import CustomPreviewUpload from "../../common/CustomPreviewUpload";
import InputMaskField from "../../common/InputComponents/InputMaskFieid";
import { useMutation } from 'react-query';
import { useDataProvider, useNotify, useRefresh } from "react-admin";

const SsnSection = (props) => {
  const [ssnInformation, setSnnInformation] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectOtherRadioButton, setSelectOtherRadioButton] =
    React.useState(false);

  const [deleteSSN, setDeleteSSN] = React.useState(false);

  const { register, watch, getValues, trigger, formState, setValue } =
    useFormContext();
  //   const useWindowWidth = useSelector(
  //     (state) => state.identification.sideMenuSlice
  //   );
  //   const [deleteDocument, result] = useDeleteDocumentMutation();
  const dataProvider = useDataProvider();
  // const queryClient = useQueryClient();
  const notify = useNotify();
  const refresh=useRefresh();
  const { mutate, isLoading, isSuccess } = useMutation("delete_document_ssn_document", (id) => dataProvider.deleteDocument(id), {
    onSuccess: (data) => {
        // setConfirmDelete(false);
        // queryClient.invalidateQueries([`${invalidateQueries}`])
        refresh();
        notify('Document deleted successfully...', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        // 
        // 
        setDeleteSSN(false);
        setValue("ssn_data.file", "");
        setValue("ssn_data.file_image", "");
        setValue("ssn_data.availableToUpload_ssn", "No");
        setFilePreview({
          ssn_id: "",
          ssn_filePath: "",
        });
        props?.handleAPIDebounced();
    },
    onError: (data) => {
        // setConfirmDelete(false);
        alert("Fail deleting document")
        setDeleteSSN(false);
    },
    onMutate: () => {
        notify('Document is Deleting...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
    }
})

const deleteDocumentHandler = (id) => {
  mutate(id)
}

  //   const deleteDocumentHandler = (id) => {
  //     deleteDocument(id).then((response) => {
  //       
  //       if (response.data?.status == 200) {
  //         setDeleteSSN(false);
  //         setValue("ssn_data.file", "");
  //         setValue("ssn_data.file_image", "");
  //         setValue("ssn_data.availableToUpload_ssn", "No");
  //       } else {
  //         alert("Error in delete document");
  //         setDeleteSSN(false);
  //       }
  //     });
  //   };
    useEffect(() => {
      // 
      const subscribe:any = watch((value, { name, type }) => {
        // 
        if (type === "change" && name === `ssn_data.isUS_citizen`) {
          trigger("ssn_data.SSNNumber");
        }
        // if (name === "ssn_data.no_doc_reason" && type === "change") {
        //   setValue("ssn_data.other_reason", "");
        // }
        // if (name === "ssn_data.other_reason" && type === "change") {
        //   setValue("ssn_data.no_doc_reason", watch("ssn_data.other_reason"));
        //   setTimeout(() => {
        //     trigger("ssn_data.no_doc_reason");
        //   }, 3000);
        // }
      });
      return () => subscribe.unsubscribe;
    }, [setValue, trigger, watch]);

    // useEffect(() => {      
    //   
    //   return () => 
    // }, []);
    
  const [filePreview, setFilePreview] = useState({
    ssn_id: "",
    ssn_filePath: "",
  });
  const trn_id = useSelector((state: any) => state?.users?.userTrnId);
  

  return (
    <AccordionDetails>
    <>
      <div className="ssn-t-body">
        <div className="row">
          <div className="col-sm-12">
            <div className="form-group cus-input-group mr-b30 mr-t30">
              {/* <CustomRadioButton
                label="Are you a US citizen?"
                register={register("ssn_data.isUS_citizen")}
              /> */}
              <CustomRadioButton
                label="Are you a US citizen?"
                register={register("ssn_data.isUS_citizen")}
                handleOnChange={() => {
                  props?.handleAPIDebounced();
                }}
              />
            </div>
          </div>
          <div className="col-sm-8 col-md-6 col-lg-4">
            <div className="emp-inputs">
            <div className="form-group cus-input-group mr-b30">
              <InputMaskField
                notRequired={
                  watch("ssn_data.isUS_citizen") == "No" ? true : false
                }
                maxLength={9}
                inputType="SSN"
                label="SSN Number"
                // register={register("ssn_data.SSNNumber")}
                source={"ssn_data.SSNNumber"}
                mask={"999-99-9999"}
                handleOnChange={() => {
                  props?.handleAPIDebounced();
                }}
              />
              {/* <MaskInputField
                notRequired={
                  watch("ssn_data.isUS_citizen") == "No" ? true : false
                }
                maxLength={9}
                type="SSN"
                label="SSN Number"
                source={"ssn_data.SSNNumber"}
                mask={"999-99-9999"}
              /> */}
            </div>
            </div>
          </div>

          <div className="col-sm-12">
            <div className="i-a-block mb-2">
              <CustomRadioButton
                onClick={() => {
                  if (!!watch("ssn_data.file")) {
                    setDeleteSSN(true);
                  }
                }}
                disable={!!watch("ssn_data.file")}
                label="Do you have proof of SSN available?"
                register={register("ssn_data.availableToUpload_ssn")}
                // handleOnChange={() => {
                //   if(watch("ssn_data.availableToUpload_ssn") == "No"){
                //     props?.handleAPIDebounced();
                //   }
                // }}
                handleOnChange={() => {
                  props?.handleAPIDebounced();
                }}
              />
            </div>
          </div>          
          {watch("ssn_data.availableToUpload_ssn") == "Yes" && (
          <div className="col-sm-12">
          <div className={`row mb-2  sb-blue m-1`}>
            <div
              className={
                filePreview.ssn_id !== ""
                  ? "col-md-8 col-sm-12 "
                  : "col-md-12 col-sm-12 "
              }
            >
              <div className="sb-blue mx-3 m-1">
                <div className="blue-flex">
                  <div className="row align-items-center px-2 print-hide">
                    <div
                      className="col-sm-12 col-md-10"
                      style={{
                        fontSize: "11px",
                        color: "#606060",
                        paddingLeft: "16px",
                      }}
                    >
                      <div className="d-flex align-items-center ">
                      <strong>{`${1}. ${"Proof of SSN "}`}:*&nbsp;</strong>
                        {/* <b>{`${"Proof of SSN"}`}:*&nbsp;</b> */}
                        <AlertDialog
                          message={`Only upload clear, complete, straight documents.`}
                        />
                      </div>
                      <div className="d-flex align-items-center m-1">
                      <strong>(SSN card or un-redacted W2)</strong>
                      </div>
                      <FormInstruction
                        message={"Click the (i) button above for details"}
                      />
                    </div>
                  </div>
                </div>
                <div className="row"> </div>
                <div className="col-md-12">
                  <div className="d-flex flex-wrap mt-2">
                    {watch("ssn_data.file") == null ? (
                      <div className="">
                        <DragDropDocumentButton
                          SourceId={1}
                          documentId={4}
                          fetchRecord={() => {}}
                          acceptedFiles={`image/*,application/pdf`}
                          invalidateQueries={"otherDoc"}
                          tag={`fileOprations_other_income`}
                          trn_id={trn_id}
                          fileError={false}
                        />
                      </div>
                    ) : (
                      <div className="d-flex">
                        <div className="px-1">
                          <FilePreview
                            doc_id={getValues(`ssn_data.application_document_id`)}
                            img={getValues(`ssn_data.file_image`)}
                            tag={"delete_document_ssn_document"}
                            invalidateQueries={"ssnDocData"}
                            file={getValues(`ssn_data.file`)}
                            handleClick={() =>
                              setFilePreview({
                                ...filePreview,
                                ssn_id: getValues(
                                  `ssn_data.application_document_id`
                                ),
                                ssn_filePath: getValues(
                                  `ssn_data.file_image`
                                ),
                              })
                            }
                            handleOnChange={() => {
                              const isCurrentFile =
                                filePreview.ssn_filePath ==
                                getValues(
                                  `ssn_data.file_image`
                                )
                                  ? true
                                  : false;
                            
                              if (isCurrentFile) {
                                setFilePreview({
                                   ssn_id: "",
                                  ssn_filePath: "",
    
                                  // index: null,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {filePreview.ssn_id !== "" && (
              <div className="col-md-4 col-sm-12 mt-2 ">
                <CustomPreviewUpload
                  filePath={filePreview.ssn_filePath}
                />
              </div>
            )}
          </div>
          </div>
          )}
          {watch(`ssn_data.availableToUpload_ssn`) == "No" && (
            <>
            <div className="row">
              <div className="col-12">
                <div className="login-radio-types">
                <NoDocReasonRadioButtons
                   handleOnchange={() => props.handleAPIDebounced()}
                  options={[
                    {
                      name: "I don't currently have access to document(s), but can provide later",
                      value:
                        "I don't currently have access to document(s), but can provide later",
                    },
                    {
                      name: "I do not have SSN",
                      value: "I do not have SSN",
                    },
                    {
                      value: "other",
                      name: "Other",
                    },
                  ]}
                  source={`ssn_data.no_doc_reason`}
                  section_id={7}
                />
                {watch("ssn_data.no_doc_reason") == "other" && (
                  <div>
                    <InputField2
                      label="Please Explain"
                      source={`ssn_data.other_reason`}
                      placeholder="Please Explain"
                      handleOnChange={() => props.handleAPIDebounced()}
                    />
                  </div>
                )}
              </div>
              </div>
            </div>
            </>
          )}
        </div>
        <Stack
          sx={{ width: "100%" }}
          style={{ textTransform: "capitalize", marginTop: "10px" }}
          spacing={2}
        >
          <ErrorMessage
            errors={formState.errors}
            name={`ssn_data.file`}
            render={({ message }) => {
              if (message) return <Alert severity="error">{message}</Alert>;

              return <></>;
            }}
          />
        </Stack>
      </div>
      <Dialog
        open={ssnInformation}
        onClose={() => {
          setSnnInformation(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Only upload clear, complete, straight documents.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setSnnInformation(false);
            }}
            autoFocus
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete document ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </Button>
          <Button
            // onClick={async () => {
            //   setTimeout(() => {
            //     deleteDocumentHandler(
            //       watch("ssn_data.application_document_id")
            //     );
            //   }, 200);
            //   setOpen(false);
            // }}
            autoFocus
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteSSN}
        onClose={() => {
          setDeleteSSN(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Are you sure want to delete
           ?`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Warning! Once deleted cannot revert back .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setDeleteSSN(false);
            }}
          >
            No
          </button>
          <button
            className="btn btn-info text-light"
            onClick={() => {
              setTimeout(() => {
                deleteDocumentHandler(
                  watch("ssn_data.application_document_id")
                );
              }, 200);
            }}
          >
            Yes
          </button>
        </DialogActions>
      </Dialog>
    </>
    </AccordionDetails>
  );
};

export default SsnSection;
