import * as React from 'react';
import Alert from '@mui/material/Alert';

export default function FormInstruction({ message,fontSize=12}) {
    return (
        <h6 className='print-hide' style={{ fontSize:fontSize, padding: '0px 4px', color: '#000', margin: '2px' }}>
            <span style={{ background: 'yellow', padding: '0 4px' }}> {message}</span>
        </h6>
    )
}
