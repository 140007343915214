import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box'; 
import AddIcon from "@mui/icons-material/Add";
import { Button } from 'react-admin';
import {
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import { useDataProvider, useNotify } from "react-admin";
import {  useMutation, useQueryClient } from 'react-query';
import moment from 'moment';
import EmpFileField from './EmpFileField';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
// const pay_frequency = [
//   { value: "Weekly", label: "Weekly" },
//   { value: "BiWeekly", label: "Bi-Weekly" },
//   { value: "Monthly", label: "Monthly" },
//   { value: "BiMonthly", label: "Bi-Monthly" },
//   { value: "Yearly", label: "Yearly" },
//   { value: "OneTime", label: "One-Time" }
// ];

// const socr_flags = [
//   { label: 'Gross pay', value: 'Gross pay' },
//   { label: 'Net pay', value: 'Net pay' },
//   { label: 'Pay period start date', value: 'Pay period start date' },
//   { label: 'Pay period end date', value: 'Pay period end date' },
//   { label: 'Pay date', value: 'Pay date' },
//   { label: 'Pay frequency', value: 'Pay frequency' }
// ];




function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const ButtonInTabs = ({ onClick, children }) => {
  return <Button onClick={onClick} children={children} sx={{minWidth:"108px"}}/>;
};

const EmployerDetails = ({ role, sourceEmpIndex, sourceEmpField, mlClassDetails, trn_id, setError, handleOnChange }) => {
  const [value, setValue] = React.useState(0);
  const empFiled=sourceEmpField;
  const notify = useNotify();
  const queryClient = useQueryClient();
  // const { identity, isLoading: identityLoading } = useGetIdentity();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { control, watch, getValues, setValue: setFormValue, trigger } = useFormContext();
  const dataProvider = useDataProvider();
  const { fields, append, prepend, remove, swap, move, update } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: `employer.${sourceEmpIndex}.files`, // unique name for your Field Array
    keyName:'empId'
  });

  
 
  // const { data: meta_d_response, isLoading, isFetching } = useQuery(
  //   ['getMetaData', 'getOne', { id: 3 }],
  //   () => dataProvider.getMetaData({ document_id: 3 ,trn_id}), { staleTime: 5, refetchOnWindowFocus: false }
  // );


  // const role = "Docuverus Super Admin"

  const { mutate, isLoading: deleteDocumentloading, isSuccess } = useMutation('deleteFile_paystub', (id) => dataProvider.deleteDocument(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries(['income'])
      notify('Paystub deleted successfully...', { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
    },
    onError: (data) => {
      alert("Fail deleting paystub")
    },
    onMutate: () => {
      notify('Paystub is Deleting...', { type: 'info', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
    }
  })
  const handleDeleteDocument = (id) => {
    mutate(id)
  }

  // const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const removeManualRecord = (index: number) => {
    const newFields = fields.filter((field: any, i: number) => i !== index);
    remove(index);
    setValue(newFields.length - 1); // Update the value state of the Tabs
  };


  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable"
          scrollButtons value={value} onChange={handleChange} aria-label="basic tabs example">
          {fields.map((field: any, index) => {
            return (<Tab
              key={field.fieldId}
              label={field.Pay_date ? `${index + 1}. ${moment(field.Pay_date).format('MM/DD/YYYY')}`
                : `${index + 1}. New paystub`
              }
              {...a11yProps(index)} />);
          })}
          {fields.length < 8 && <ButtonInTabs
            onClick={() => {
              append({
                income_row_id: "0",
                application_document_id: "0",
                not_save:true,
                All_text: "",
                End_date: null,
                Gross_Pay: 0,
                Net_Pay: 0,
                Pay_date:"" ,
                Pay_frequency: "",
                annual_income: 0,
                monthly_income: 0,
                Start_date: "",
                file_name: "",
                is_Duplicate: "",
                path: "",
                path_image: "",
                SummaryTable: [],
                earning: [],
                deduction: [],
                manualRecord:true,
              })
              setValue(fields?.length)
            }}
          >
            <>
              <AddIcon color="primary" />
             <span className='text-capitalize' style={{fontSize:12,fontFamily: 'OpenSans-Bold'}}>Manual Record</span>
            </>
          </ButtonInTabs>}

        </Tabs>
      </Box>
      {fields.map((field: any, index) => {
        return <EmpFileField key={field.empId} removeManualRecord={() => removeManualRecord(index)} empSource={empFiled}  setError={setError} trn_id={trn_id} mlClassDetails={mlClassDetails} field={field} index={index} value={value} handleOnChange={handleOnChange}  sourceEmpIndex={sourceEmpIndex} role={role} />
      })}
    </Box>
  );
}

export default EmployerDetails;