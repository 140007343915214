import React from 'react'
import ArchiveIcon from '@mui/icons-material/Archive';
import { Box, Button, InputAdornment } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { TextInput, required, useUpdate, useNotify, useRefresh, NumberInput, useGetOne } from 'react-admin';
import { useForm, FormProvider, get } from 'react-hook-form';
import { Button as MuiButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import { useParams } from "react-router-dom";
import { Update } from '@mui/icons-material';
import { JSONTree } from 'react-json-tree';
import InputDateField from '../common/InputComponents/InputDateFieldOld';
import InputCurrencyField from '../DocumentAnalysis/components/inputComponents/InputCurrencyFiled';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import InputTextArea from '../common/InputComponents/InputTextArea';
import LoadingSpinner from '../common/loadingSpinner';
import moment from "moment";
import InputField2 from '../common/InputComponents/InputField2';
import InputSelectField from '../common/InputComponents/InputSelectField';
import { inherits } from 'util';
 
export default function AddressRentEdit(props) {    
    const { id:parmasId } = useParams();
    const id = parmasId || props.record.SubscriberID;
    const notify = useNotify();
    const refresh = useRefresh();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // get application detail data
    const { data: applicationDetail, isLoading: isLoadingData } = useGetOne(
        "getnewapplicationdetail",
        {
        id,
        }
    );

    // form validation schema 
    const validationSchema = Yup.lazy((value) =>
        Yup.object().shape({
        address: Yup.string().trim("Address is required!").required("Address is required!").nullable().max(150, "maximum 150 characters is required"),
        city: Yup.string().trim("Desired city is required!").required("Desired city is required!").nullable(),
        state: Yup.object()
          .shape({
            value: Yup.string().required("Desired state is required!").nullable(),
            label: Yup.string().nullable(),
          })
          .nullable()
          .required("Desired state is required!"),
        rent: Yup.string()
          .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
          .min(1, "Greater than 0 value required")
          .nullable()
          .test("rent", "Rent is required!", async (value) => {
            return value == null || parseInt(value) > 0 ? true : false;
          }),
          desired_movein_date : 
          props?.desiredMoveInDate &&
          Yup.string().required("Date is required!").nullable(),
        })
    );
    
    // finding a state default value
    const findStatedefaultValue = applicationDetail?.States?.find(item => item?.StateShortCode == props?.record?.AppliedForAddress?.split(",")[2]?.trim())
    const statedefaultValue = {
        label: findStatedefaultValue ? findStatedefaultValue?.StateShortCode + " - " + findStatedefaultValue?.StateName : null,
        value: findStatedefaultValue ? findStatedefaultValue?.ID : null,
    }

    // form initial states
    const form = useForm({
        defaultValues: {
            address: props.record?.AppliedForAddress?.split(",")[0],
            city: props.record?.AppliedForAddress?.split(",")[1]?.trim(),
            state: findStatedefaultValue ? statedefaultValue : null,
            rent:props.record.AppliedForRent,
            desired_movein_date: props.record.DesiredDate
        },
        resolver: yupResolver(validationSchema),
    });

    // Destructuring form
    const { reset } = form;
    // reset form data
    React.useEffect(() => {
      form?.reset({
        address: props.record?.AppliedForAddress?.split(",")[0],
        city: props.record?.AppliedForAddress?.split(",")[1]?.trim(),
        state: findStatedefaultValue ? statedefaultValue : null,
        rent: props.record.AppliedForRent,
        desired_movein_date: props.record.DesiredDate,
      });
    }, [props?.record]);

    const [update, { data, isLoading, error }] = useUpdate();

    // form on onsubmit handler
    const onSubmit = (value) => {
        const sendData = {...value, desired_movein_date: value?.desired_movein_date ?  moment(value?.desired_movein_date).format("MM/DD/YYYY"): null}
        update('editapplicationdetail', { id: props.record.id, data: sendData }, {
            onSuccess: (d) => {
                notify(`Data Updated`, { type: 'success', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
                refresh();
                handleClose();
            }, onError: (e: any) => notify(`Operation fail:${e.message}`, { type: 'error', anchorOrigin: { vertical: 'top', horizontal: 'right' }})
        })
    }
    return (
        <>
            <EditIcon onClick={handleOpen} color='primary' fontSize='small' style={{fontSize:props?.fontSize ? props?.fontSize : "inherits"}}/>
            <Dialog
                fullWidth
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>
                    Edit
                </DialogTitle>
                <DialogContent>
                    <FormProvider {...form}>
                        <form onSubmit={form.handleSubmit(onSubmit)}>
                            <div className="row">
                              <div className="col-12 mr-b10">
                                <InputTextArea register={"address"} label="Address" placeholder="Address" labelClass={"lable-title"} labelAsteriskClass={"color-red"} additionalInputClass={"cus-control"} styleProperty={{ height: "auto" }} messageText={false} requiredClass={{fontSize: "70%"}}/>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 mr-b10">
                              <InputField2
                                    label="Desired City"
                                    source={`city`}
                                    placeholder="Desired City"
                                    labelClass={"lable-title"}
                                    additionalInputClass={"cus-control"}
                                  />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 mr-b10">
                                <InputSelectField
                                    label="Desired State"
                                    source={`state`}
                                    options={applicationDetail?.States?.map((item) => ({
                                        label: item.StateShortCode + " - " + item.StateName,
                                        value: item.ID,
                                    }))}
                                    labelAsteriskClass={"color-red"}
                                    labelClass={"lable-title"}
                                    requiredClass={{
                                        fontSize: "70%",
                                    }}
                                    />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 mr-b10">
                                <InputCurrencyField label="Rent" source={`rent`} placeholder="Rent" required={true} labelClass={"lable-title"} requiredClass={{ fontSize: "70%"}}/>
                              </div>
                            </div>
                            {props?.desiredMoveInDate ? <div className="row">
                              <div className="col-12 mr-b10">
                              <InputDateField source={"desired_movein_date"} label="Desired Move In Date" labelClass={"lable-title"}/>
                              </div>
                            </div> : ""}
                            <Box display={'flex'} justifyContent={'end'}>
                                <Button sx={{ mx: 1 }} onClick={() => {handleClose(); reset();}} variant='outlined' color='secondary' type='button' >
                                    Cancel
                                </Button>

                                <Button startIcon={<EditIcon />} sx={{ mx: 1 }} variant='contained' type='submit'>
                                    Update
                                </Button>
                            </Box>
                        </form>
                    </FormProvider>
                </DialogContent>
                {/* <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions> */}
            </Dialog>
            {isLoading ? <LoadingSpinner /> : ""}
        </>
    )
}
