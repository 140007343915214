import {
  Avatar,
  Button as MuiButton,
  CircularProgress,
  Grid,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
// import { ThemeProvider, createTheme } from '@mui/material/styles';
import WarningIcon from "@mui/icons-material/Warning";
import { Button, useGetOne } from "react-admin";
import { NumericFormat } from "react-number-format";
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { JSONTree } from "react-json-tree";
import moment from "moment";
import AddressRentEdit from "./AddressRentEdit";
import BasicPopover from "./GetsubscriberinboxListDetails";
import WarningPrelimNotice from "./WarningPrelimNotice";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { useNavigate, useLocation, useParams } from "react-router";
import { ArrowBack } from "@mui/icons-material";
import DateComponent from "./DateComponent";

const ApplicantDetailBox = (props) => {
  // return <JSONTree data={props} />;
  
  const navigate = useNavigate();
  // No changes need in this compoment as of now
  const trn_id = useSelector((state: RootState) => state.users.userTrnId);
  const { id, appType } = useParams();
  // call get application inbox details data
  const { data, isLoading, error } = useGetOne("getapplicationinboxdetails", {
    id: props?.appDetails?.id,
  });
  const {
    data: applicantDatasub,
    isLoading: loading,
    refetch,
  } = useGetOne("getApplicantDetails", { id: trn_id }, { enabled: !!trn_id });

  const applicationStatusBackgroundColor = (
    ApplicationStatus,
    ScreeningDetails
  ) => {
    if (
      ApplicationStatus === "Completed-Approved" 
      // || ApplicationStatus === "Screening"
    ) {
      return "#E6FDF0";
    } else if (ApplicationStatus === "Completed-Unacceptable") {
      return "#F8ECE2";
    } else if (ApplicationStatus === "CompletedConditionallyApproved") {
      return "#FFF8E2";
    } else if (ApplicationStatus == "Review Pending") {
      return "#ecfbff";
    }  else if (ApplicationStatus == "Application In Progress") {
      return "#DDF8FF";
    } else if (ApplicationStatus == "Submitted") {
      return "#B0EFFF";
    } else if (
      ScreeningDetails == "ConditionallyApproved" ||
      ScreeningDetails == "Conditionally Approved"
    ) {
      return "#FFF8E2";
    } else if (ScreeningDetails == "Unacceptable") {
      return "#F8ECE2";
    } else if (
      ScreeningDetails == "NoAssessment" ||
      ScreeningDetails == "No Assessment"
    ) {
      return "#fff";
    }
    else if (
      ScreeningDetails == "Approved"
    ) {
      return "#E6FDF0";
    }

    return "#E9E9E9";
  };
  const applicationStatusTextColor = (
    ApplicationStatus,
    ScreeningDetails
  ) => {
    if (
       ApplicationStatus === "Completed-Approved" 
    
      // ApplicationStatus === "Screening"
    ) {
      return "#46A96F";
    } else if (ApplicationStatus === "Completed-Unacceptable") {
      return "#FF4848";
    } else if (ApplicationStatus === "CompletedConditionallyApproved") {
      return "#F9BB04";
    } else if (ApplicationStatus == "Review Pending") {
      return "#2dc3e8";
    }  else if (ApplicationStatus == "Application In Progress") {
      return "#1CA2C2";
    } else if (ApplicationStatus == "Submitted") {
      return "#127790";
    } else if (
      ScreeningDetails == "ConditionallyApproved" ||
      ScreeningDetails == "Conditionally Approved"
    ) {
      return "#F9BB04";
    } else if (ScreeningDetails == "Unacceptable") {
      return "#FF4848";
    } else if (
      ScreeningDetails == "NoAssessment" ||
      ScreeningDetails == "No Assessment"
    ) {
      return "#000000";
    } else if (
      ScreeningDetails == "Approved"
    ) {
      return "#46A96F";
    }

    return "#909090";
  };
  // const theme = createTheme({
  //   palette: {
  //     error: {
  //     main: '#f8bbd0',
  //     },
  //   },
  // });

  const appDetails = data?.data[0];
  // state data for new tab NewtabHandler
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const encodedData: any = searchParams.get("data");
  let locationState: any = {};
  if (encodedData) {
    locationState = JSON.parse(atob(encodedData));
  }
  return (
    <>
      <div className="row">
        {appType == "1" && (
          <div
            className=""
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              flexDirection: "row",
            }}
          >
            <img
              src={applicantDatasub?.subscriber_logo}
              alt="Logo"
              style={{
                maxWidth: "50px",
                height: "auto",
                marginRight: "5px",
                display: applicantDatasub?.subscriber_logo ? "initial" : "none", // Hide the entire image if the source is falsy
              }}
              onError={(e) => {
                const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                target.style.display = "none"; // Hide the broken image
              }}
            />

            {!loading && (
              <strong>
                {applicantDatasub?.subscriber_details?.CompanyName}
              </strong>
            )}
          </div>
        )}
        <div className="col-12-sm d-flex">
          <br />
          <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
              <Typography sx={{ fontWeight: "bold" }}>
                <button
                  title="Go Back"
                  style={{ color: "#2cc0e4" }}
                  className="btn btn-link"
                  onClick={() =>
                    navigate(
                      !location?.state ? locationState?.from?.pathname : -1
                    )
                  }
                >
                  {" "}
                  <ArrowBack />
                </button>
                <span>Applicant Details</span>
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        {isLoading ? (
          <div className="col-sm-12">
            <div className="details-card-row mr-b30">
              <div className="static-d-info d-flex justify-content-center align-items-center">
                <CircularProgress size="30px" />
                <h5>&nbsp; &nbsp; Loading...</h5>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-sm-12">
            <div className="details-card-row 2 mr-b30">
              <div className="static-d-info">
                <label>App ID</label>
                <h5 style={{ fontWeight: "bold" }}>
                  {appDetails?.id ? appDetails?.id : "NA"}
                </h5>
              </div>
              <div className="static-d-info">
                <label>App Date</label>

                <h5>
                  <DateComponent date={appDetails?.AppDate} />
                </h5>
              </div>
              <div className="static-d-info">
                <label>Agent</label>
                <h5
                  title={appDetails?.Agent}
                  style={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    // display: "inline-block",
                  }}
                >
                  {appDetails?.Agent ? appDetails?.Agent : "NA"}
                </h5>
              </div>
              <div className="static-d-info">
                <label>Property/Office</label>
                <h5>{appDetails?.Property ? appDetails?.Property : "NA"}</h5>
              </div>
              <div className="static-d-info">
                <label>
                  App Address
                  {appDetails?.Subscribertype == "Real Estate" &&
                  appDetails?.ApplicationStatus !== "Invited" ? (
                    <IconButton size="small">
                      <AddressRentEdit
                        record={appDetails}
                        desiredMoveInDate
                        fontSize={"8px"}
                      />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </label>
                <h5
                  title={appDetails?.AppliedForAddress}
                  style={{
                    maxWidth: "150px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    // display: "inline-block",
                  }}
                >
                  {appDetails?.AppliedForAddress
                    ? appDetails?.AppliedForAddress
                    : "NA"}
                </h5>
                {/* <h5>
                  {appDetails?.AppliedForAddress
                    ? appDetails?.AppliedForAddress
                    : "NA"}
                </h5> */}
              </div>
              <div className="static-d-info">
                <label>
                  Rent
                  {appDetails?.Subscribertype == "Real Estate" &&
                  appDetails?.ApplicationStatus !== "Invited" ? (
                    <IconButton size="small">
                      <AddressRentEdit
                        record={appDetails}
                        desiredMoveInDate
                        fontSize={"8px"}
                      />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </label>
                <h5>
                  <NumericFormat
                    thousandSeparator=","
                    displayType="text"
                    thousandsGroupStyle="thousand"
                    decimalScale={2}
                    prefix="$"
                    fixedDecimalScale
                    value={
                      appDetails?.AppliedForRent
                        ? appDetails?.AppliedForRent
                        : "NA"
                    }
                  />
                </h5>
              </div>
              <div className="static-d-info">
                <label>Desired City</label>
                <h5>
                  {" "}
                  {appDetails?.AppliedForAddress
                    ? appDetails?.AppliedForAddress?.split(",")[1]
                    : "NA"}
                </h5>
              </div>
              <div className="static-d-info">
                <label>
                  Desired Move In
                  {appDetails?.Subscribertype == "Real Estate" &&
                  appDetails?.ApplicationStatus !== "Invited" ? (
                    <IconButton size="small">
                      <AddressRentEdit
                        record={appDetails}
                        desiredMoveInDate
                        fontSize={"8px"}
                      />
                    </IconButton>
                  ) : (
                    ""
                  )}
                </label>
                <h5>
                  {appDetails?.DesiredDate
                    ? moment(appDetails?.DesiredDate).format("MM/DD/YYYY")
                    : "NA"}
                </h5>
              </div>
              <div className="static-d-info">
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                >
                  <MuiButton
                    variant="contained"
                    disableElevation={true}
                    style={{
                      // color: "#127790",
                      fontSize: "12px",
                      // height: "32px",
                      textTransform: "capitalize",
                      cursor: "text",
                      fontFamily: "OpenSans-SemiBold",
                      padding: "5px 10px",
                      background:
                      applicationStatusBackgroundColor(appDetails?.ApplicationStatus,appDetails?.ScreeningDetails),
                        

                      color:
                      applicationStatusTextColor(appDetails?.ApplicationStatus,appDetails?.ScreeningDetails),
                    }}
                  >
                    {appDetails?.ApplicationStatus == "Application In Progress"
                      ? "In Progress"
                      : appDetails?.ApplicationStatus ==
                        "CompletedConditionallyApproved"
                      ? "Completed-Conditionally Approved"
                      : appDetails?.ApplicationStatus}
                  </MuiButton>
                </Box>
              </div>

              {appDetails?.Score > 0 && (
                <div className="static-d-info">
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                  >
                    <Avatar
                      sx={{
                        bgcolor:
                          // appDetails?.ApplicationStatus ===
                          //   "Completed-Approved" ||
                          // appDetails?.ApplicationStatus ===
                          //   "CompletedConditionallyApproved" ||
                          // appDetails?.ApplicationStatus ===
                          //   "Completed-Unacceptable"
                          //   ? appDetails?.ApplicationStatus ===
                          //     "Completed-Approved"
                          //     ? "#E6FDF0"
                          //     : appDetails?.ApplicationStatus ===
                          //       "CompletedConditionallyApproved"
                          //     ? "#FFF8E2"
                          //     : appDetails?.ApplicationStatus ===
                          //       "Completed-Unacceptable"
                          //     ? "#F8ECE2"
                          //     : ""
                          //   :
                          appDetails?.ScreeningDetails ===
                            "ConditionallyApproved" ||
                          appDetails?.ScreeningDetails ===
                            "Conditionally Approved"
                            ? "#FFF8E2"
                            : appDetails?.ScreeningDetails === "Unacceptable"
                            ? "#F8ECE2"
                            : appDetails?.ScreeningDetails === "NoAssessment" ||
                              appDetails?.ScreeningDetails === "No Assessment"
                            ? "#fff"
                            : appDetails?.ScreeningDetails === ""
                            ? "inherit"
                            : "#E6FDF0",
                        border:
                          // appDetails?.ApplicationStatus ===
                          //   "Completed-Approved" ||
                          // appDetails?.ApplicationStatus ===
                          //   "CompletedConditionallyApproved" ||
                          // appDetails?.ApplicationStatus ===
                          //   "Completed-Unacceptable"
                          //   ? appDetails?.ApplicationStatus ===
                          //     "Completed-Approved"
                          //     ? "1px solid #46A96F"
                          //     : appDetails?.ApplicationStatus ===
                          //       "CompletedConditionallyApproved"
                          //     ? "1px solid #F9BB04"
                          //     : appDetails?.ApplicationStatus ===
                          //       "Completed-Unacceptable"
                          //     ? "1px solid #FF4848"
                          //     : ""
                          //   :
                          appDetails?.ScreeningDetails ===
                            "ConditionallyApproved" ||
                          appDetails?.ScreeningDetails ===
                            "Conditionally Approved"
                            ? " 1px solid #F9BB04"
                            : appDetails?.ScreeningDetails === "Unacceptable"
                            ? "1px solid #FF4848"
                            : appDetails?.ScreeningDetails === "NoAssessment" ||
                              appDetails?.ScreeningDetails === "No Assessment"
                            ? "1px solid #000000"
                            : appDetails?.ScreeningDetails === ""
                            ? "inherit"
                            : "1px solid #46A96F",
                        color:
                          // (appDetails?.ApplicationStatus ===
                          //   "Completed-Approved" ||
                          // appDetails?.ApplicationStatus ===
                          //   "CompletedConditionallyApproved" ||
                          // appDetails?.ApplicationStatus ===
                          //   "Completed-Unacceptable")
                          //   ? appDetails?.ApplicationStatus ===
                          //     "Completed-Approved"
                          //     ? "#46A96F"
                          //     : appDetails?.ApplicationStatus ===
                          //       "CompletedConditionallyApproved"
                          //     ? "#F9BB04"
                          //     : appDetails?.ApplicationStatus ===
                          //       "Completed-Unacceptable"
                          //     ? "#FF4848"
                          //     : ""
                          //   :
                          appDetails?.ScreeningDetails ===
                            "ConditionallyApproved" ||
                          appDetails?.ScreeningDetails ===
                            "Conditionally Approved"
                            ? "#F9BB04"
                            : appDetails?.ScreeningDetails === "Unacceptable"
                            ? "#FF4848"
                            : appDetails?.ScreeningDetails === "NoAssessment" ||
                              appDetails?.ScreeningDetails === "No Assessment"
                            ? "#000000"
                            : appDetails?.ScreeningDetails === ""
                            ? "inherit"
                            : "#46A96F",
                      }}
                    >
                      {appDetails?.Score}
                    </Avatar>

                    <Box display={"flex"} justifyContent={"center"}>
                     
                      {appDetails.SecondaryApp && !appDetails.EmailStatus && (
                        <IconButton sx={{ p: "4px" }} size="small">
                          <WarningPrelimNotice showWithScoreAsIcon={true} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                </div>
              )}
              <div className="static-d-info">
                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"start"}
                >
                  <label> RI%</label>
                  <BasicPopover />
                </Box>

                <h5>{appDetails?.RI ? appDetails?.RI : "NA"}</h5>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ApplicantDetailBox;
