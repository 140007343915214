import * as yup from "yup";

const formValidation = {
      name: yup
        .string()
        .typeError("Required!")
        .min(2, "Minimum 2 characters required")
        .max(75, "Maximum 75 characters are allowed")
        .required("Required!"),
      StringRequiredValidation: yup.string().nullable().required("Required!"),
      streetAddress: yup
        .string()
        .trim("Required!")
        .required("Required!")
        .max(500, "Maximum 500 characters are allowed")
        .nullable()
        .matches(/^$|^\S+.*/g, "Enter a valid value")
        .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid Value"),
      zipCode: yup
        .string()
        .typeError("Required!")
        .required("Required!")
        .matches(
          /^(?!0{5})[0-9]{5}(?:-[0-9]{4})?$/g,
          "Please enter valid zip code"
        ),
      city: yup
        .string()
        .trim("Required!")
        .required("Required!")
        .nullable()
        .max(50, "Maximum 50 characters are allowed")
        .matches(/^$|^\S+.*/g, "Enter a valid value")
        .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "Enter a valid Value"),
      username:yup
          .string()
          .typeError("Required!")
          .trim("Required!")
          .required("Required!"),
      subscriberCompanyWebsite: yup
        .string()
        .url("Enter a valid URL")
        .nullable(),
      email: yup
        .string()
        .nullable()
        .email("Invalid email format")
        .required("Email is required!"),
      date: yup.date().required().typeError("Required!"),
      feeValidation: yup
        .string()
        .matches(/^(\d*[0-9])|^$/, "Only Numbers Allowed")
        .required("Required!")
        .max(12, "Maximum 12 digits are allowed")
        .nullable()
        .test("rent", "Required!", async (value) => {
          return value == null || value == undefined ? false : true;
        }),
      processingFee: yup
        .string()
        .nullable()
        .max(12, "Maximum 12 digits are allowed")
        .test(
          "MinValue",
          "Processing fee cannot be less than $5",
          async (value: any) => {
            return value >= 5 || parseInt(value) >= 5 ? true : false;
          }
        ).test("rent", "Required!", async (value) => {
          return value !== null ? true : false;
        }),
      validationForDropDown: yup
        .object()
        .shape({
          value: yup.string().required("Required!").nullable(),
          label: yup.string().nullable(),
        })
        .nullable()
        .required("Required!"),
       mobileNo: yup
        .string()
        .required("Required!")
        .typeError("Required!")
        .nullable()
        .test("phone", "Min 10 digits are required", (value) => {
          let temp: any = value?.toString().replace(/\D/g, "");
          return ( temp?.length > 10 ) ? true : false;
        }),
        mobileNoNotRequired:yup.string().nullable().test("phone", "Min 10 digits are required", (value) => {
          let temp: any = value?.toString().replace(/\D/g, "");
          return (temp?.length<2 || temp?.length > 10 ) ? true : false;
        }),
        NumberValidation: yup.string().required("Required!").typeError("Required!").max(10, "Maximum 10 digits are allowed"),
        PaymentDate:yup.string()
        .nullable()
        .required("Required!")
        .test("only_number", "Please enter a date between 1 to 31", (value: any) => {
          if (value === "" || value === null) {
            return true;
          }
          const regex =
            /^(?:3[01]|[12][0-9]|0?[1-9])$/;
          return regex.test(value);
        }),
        stringNumberValidation:yup
        .string()
        .nullable()
        .required("Required!")
        .test("only_number", "Not valid", (value: any) => {
          if (value === "" || value === null) {
            return true;
          }
          // const regex = /^\d{3}-\d{2}-\d{4}$/;
          const regex =
            /(?=.*?\d)^\$?(([1-9]\d{0,2}(,\d{3})*)|\d+)?(\.\d{1,2})?$/;
          return regex.test(value);
        })
}

export default formValidation;