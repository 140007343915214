import React, { useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import placeholderImg from "../../assets/images/placeholder-payslip.png";
import dlt from "../../assets/images/dlt.svg";
import { useDataProvider, useGetIdentity, useNotify } from "react-admin";
import { useMutation } from "react-query";
import { useQueryClient } from "react-query";
import DeleteIcon from "@mui/icons-material/Delete";
import * as ImageTracer from "imagetracerjs";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import DeleteContainedBtn from "./DeleteContainedBtn";
import DeleteFileConfirmBtn from "./DeleteFileConfirmBtn";
import { JSONTree } from "react-json-tree";
import { AiFillWarning } from "react-icons/ai";

export default function FilePreview({
  img,
  is_Duplicate=0,
  alt = "",
  doc_id,
  file_name = "",
  isId = false,
  tag,
  invalidateQueries,
  file = img,
  isDocUpload = false,
  handleClick,
  handledelete = ()=> {},
  ...props
}) {
  // 
  const dataProvider = useDataProvider();
  const queryClient = useQueryClient();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const notify = useNotify();
  const Identity = useGetIdentity();
  // ImageTracer.imageToSVG( img, ImageTracer.appendSVGString, { ltres:0.1, qtres:1, scale:10, strokewidth:5 });
  //   ImageTracer.loadImage(
  //    img,
  //     function(canvas){
  //       var imgd = ImageTracer.getImgdata( canvas );
  //       
  //       

  //      }
  // );

  // ImageTracer.imageToTracedata(
  //   img,
  //   function(tracedata){ alert( JSON.stringify( tracedata ) ); },
  //   { ltres:0.1, qtres:1, scale:10 }
  // );
  
  const hasSSToken = (img = true,url) => {
    if (!img) {
      url = props?.fileObj?.path;
    }
    if (!url) {
      return url;
    } else if (url.includes("?")) {
      return url + "&";
    } else {
      return url;
    }
  };
  return (
    <>
    {/* <JSONTree data={Identity.identity?.tokendata}/> */}

      {img && (
        <div className="d-inline-flex flex-column flex-wrap">
           {is_Duplicate == 1 ? <p className='muted text-warning small'><AiFillWarning/> { 'Duplicate'}</p>:<p>&nbsp;</p>}
          
          <img
            src={hasSSToken(true,img)+`?token=`+Identity.identity?.tokendata}
            style={{ maxWidth: "80px" }}
            className={"img-thumbnail ratio ratio-1x1"}
            alt=""
            onClick={handleClick}
            
          />
          <div className="" style={{ textAlign: "center" }}>
            <p
              className="small pt-1"
              title={file_name}
              style={{ textAlign: "center", fontSize: 10 }}
            >
              {file_name
                ? `${file_name.substring(0, 9)}...`
                : img &&
                img?.split("/")?.pop()?.split("_")?.pop()?.substring(0, 15)?.replace(/%20/g, '')?.replace(/%2/g, '')?.replace(/%/g, '') +
                "..."}
            </p>
            <DeleteFileConfirmBtn
              doc_id={doc_id}
              invalidateQueries={invalidateQueries}
              tag={tag}
              petIndex={props?.petIndex} // passing index for pet images delete from store
              handleOnChange={props?.handleOnChange}// passing this to call onsubmit after uplode a pet images
            
           />
          </div>
        </div>
      )}
    </>
  );
}
