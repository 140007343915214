import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Page, Document, pdfjs } from "react-pdf";
import CustomPreviewUploadImage from "./CustomPreviewUploadImage";
import { useGetIdentity } from "ra-core";
import { JSONTree } from "react-json-tree";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function CustomPreviewUpload({ filePath: filePath,startpage=1,endpage=1}) {
  //const [scale, setScale] = useState(1);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [numPages, setNumPages] = useState(1);
  const [currentPages, setCurrentPages] = useState(1);
  const Identity = useGetIdentity();
  useEffect(()=>{
    if(startpage>=1)
    setCurrentPages(startpage)
  },[startpage])
  const hasSSToken = (url) => {
    if (!url) {
      return url;
    } else if (url.includes("?")) {
      return url + "&";
    } else {
      return url;
    }
  };
  function onDocumentLoadSuccess({ numPages }) {
    console.log("avi" + numPages);
    setNumPages(numPages);
  }

  const downloadFileData = (imageUrl: any) => {
    window.open(hasSSToken(filePath) + `?token=` + Identity.identity?.tokendata, "_blank");
  };
  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => prevZoom + 0.5);
  };

  const handleZoomOut = () => {
    if (zoomLevel > 0.5) {
      setZoomLevel((prevZoom) => prevZoom - 0.5);
    }
  };

  const handleRotateRight = () => {
    setRotation(rotation + 90);
  };
  const handleRotateLeft = () => {
    setRotation(rotation - 90);
  };
  const handleNext = () => {
    setCurrentPages((V) => V + 1);
  };

  const handlePrev = () => {
    setCurrentPages((V) => V - 1);
  };
  const imageStyles = {
    transform: `scale(${zoomLevel * 100})`,
    transformOrigin: "top left", // Set the transform origin to top left
  };
  const [isImage, setIsImage] = useState(false);
  return (
    <div className="upload-preview mb-3">
            <div className="upload-header d-flex justify-content-between">
        <div>
          <span>
            File Preview ({currentPages}/{numPages})
          </span>
        </div>
        <div>
          <Button
            className="print-hide"
            onClick={downloadFileData}
            startIcon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                viewBox="0 0 16 16"
                className="bi bi-file-earmark-arrow-down"
              >
                <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"></path>
                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"></path>
              </svg>
            }
          // onClick={() => handleHideAll(isHideAll ? false : true)}
          >
            {/* {isHideAll ? "Unhide All" : "Hide All"} */}
            Download Documents
          </Button>
        </div>
      </div>
      <div className="upload-preview-body position-relative w-auto">
        {filePath?.split(".")?.pop()?.split("?")[0] !== "pdf" ? (
          <CustomPreviewUploadImage
            file_path={hasSSToken(filePath) + `?token=` + Identity.identity?.tokendata}
            currentPages={currentPages}
            numPages={numPages}
            onDocumentLoadSuccess={onDocumentLoadSuccess}
            rotation={rotation}
            zoomLevel={zoomLevel}
          />
        ) : (
          <div className="position-absolute top-0 bottom-0 left-0 right-0">
            <Document file={hasSSToken(filePath) + `?token=` + Identity.identity?.tokendata} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                renderTextLayer={false}
                pageNumber={currentPages}
                rotate={rotation}
                scale={zoomLevel}
              />
            </Document>
          </div>
        )}
      </div>
      <div className="upload-action">
        <div className="card-footer text-center">
          <a className="btn btn-light m-1 btn-sm" onClick={handleZoomIn}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              className="bi bi-zoom-in"
            >
              <path
                fillRule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
              ></path>
              <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"></path>
              <path
                fillRule="evenodd"
                d="M6.5 3a.5.5 0 0 1 .5.5V6h2.5a.5.5 0 0 1 0 1H7v2.5a.5.5 0 0 1-1 0V7H3.5a.5.5 0 0 1 0-1H6V3.5a.5.5 0 0 1 .5-.5z"
              ></path>
            </svg>
          </a>
          <a className="btn btn-light m-1 btn-sm" onClick={handleZoomOut}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              className="bi bi-zoom-out"
            >
              <path
                fillRule="evenodd"
                d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"
              ></path>
              <path d="M10.344 11.742c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1 6.538 6.538 0 0 1-1.398 1.4z"></path>
              <path
                fillRule="evenodd"
                d="M3 6.5a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5z"
              ></path>
            </svg>
          </a>
          <a className="btn btn-light m-1 btn-sm" onClick={handleRotateRight}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              className="bi bi-arrow-clockwise"
            >
              <path
                fillRule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              ></path>
              <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"></path>
            </svg>
          </a>
          <a className="btn btn-light m-1 btn-sm" onClick={handleRotateLeft}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
              className="bi bi-arrow-counterclockwise"
            >
              <path
                fillRule="evenodd"
                d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
              ></path>
              <path d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"></path>
            </svg>
          </a>
          {currentPages > 1 && <a className="btn btn-light m-1 btn-sm" onClick={handlePrev}>
            Prev
          </a>}
          {currentPages < numPages && <a className="btn btn-light m-1 btn-sm" onClick={handleNext}>
            Next
          </a>}
        </div>
      </div>
    </div>
  );
}
