import React, { useEffect, useState, useRef, useMemo } from "react";
// import React, { useEffect, useState, useOutletContext, useRef } from "react";
// import Address from "./address/address";
// import PersonalDetails from "./personal-details/personal-details";
// import ProofOfIdentity from "./proof-of-identity/proof-of-identity";
// import Ssn from "./ssn/ssn";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DevTool } from "@hookform/devtools";
// import {
//   useGetIdentificationDataQuery,
//   useGetSectionStatusQuery,
//   useSaveIdDetailsMutation,
// } from "../../../services/identificationApi";
import { toast, ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
// import { sideNavAction } from "../../../store/identification/sideMenuSlice";
// import { formSubmitStatusRest } from "../../../store/registration/fromSubmitSlice";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { BsCheck2Circle } from "react-icons/bs";
// import { tabsStateAction } from "../../../store/identification/tabStateSlice";
import MuiAlert from "@mui/material/Alert";
import CloseIcon from "@mui/icons-material/Close";
// import SupportSection from "../../common/SupportSection/SupportSection";
import { RiInformationLine } from "react-icons/ri";
import { JSONTree } from "react-json-tree";

// New import
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useCreate, useGetOne, useNotify, useRefresh } from "react-admin";
import PersonalDetailSection from "./PersonalDetailSection";
import debouce from "lodash.debounce";

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });
function validateDOB(dob) {
  var today = new Date();
  var minAge = new Date(
    today.getFullYear() - 100,
    today.getMonth(),
    today.getDate()
  );
  var maxAge = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );

  if (dob > maxAge || dob < minAge) {
    return false;
  }
  return true;
}
const PersonalDetailsAccordion = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [licenseInformation, setLicenseInformation] = React.useState(false);

  const submitBtnRef: any = useRef(null);
  const [selectedName, setSelectedName] = useState(null);
  const [selectedDOB, setSelectedDOB] = useState(null);
  const [open, setOpen] = useState(false);
  //*********FOR PERSONAL DETAILS SIDE COMPLETION***********//
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailIDError, setEmailIdError] = useState(false);
  const [mobileNoError, setMobileNoError] = useState(false);
  const [dobError, setDobError] = useState(false);
  const [aliasFirstNameError, setAliasFirstNameError] = useState(false);
  const [aliasLastNameError, setAliasLastNameError] = useState(false);
  const [ssnError, setSSNError] = useState(false);
  const [clickHere, setClickHere] = useState(true);

  // const [saveIdDetails, result] = useSaveIdDetailsMutation();
  const refresh = useRefresh();
  const notify = useNotify();
  const [create, { isLoading, error }] = useCreate();
  const saveIdDetails = (payload: any) => {
    return create(
      "saveiddetail",
      { data: payload },
      {
        onSuccess: (data) => {
          notify(`Request processed successfully.`, { type: "success", anchorOrigin: { vertical: 'top', horizontal: 'right' }});
          refresh();
          // reset();
        },
        onError: (e: any) =>
          notify(`Operation fail:${e.message}`, { type: "error", anchorOrigin: { vertical: 'top', horizontal: 'right' }}),
      }
    );
  };

  const trn_id = props.Trnid; //useSelector((state: any) => state?.users?.userTrnId);

  // Need to define in redux
  const nameMatched = {
    applicantNameMatched: true,
    applicantDOBMatched: true,
  };

  // get Identification Data
  const { data: getIdentificationData, isLoading: isLoadingData,} = useGetOne(
    "getidentification",
    {
      // id:27783,
      id: trn_id,
    },{
      refetchOnWindowFocus:false
    }
  );

  const notEmptyFieldsMethod = (
    value,
    path,
    createError,
    myFailureMessage,
    setNameError
  ) => {
    try {
      if (value) {
        // 
        setNameError(false);
        return true;
      }
      // 

      setNameError(true);
      return createError({ path, message: myFailureMessage });
    } catch (error) {
      // console.error("🚀 ~ file: identification.js ~ line 96 ~ error", error);
    }
  };

  

  const applicant_data_schema = yup.object().shape({
    first_name: yup
      .string()
      .typeError("required")
      .max(75, "maximum 75 characters is required")
      .test("is_empty_field", function (value) {
        const { path, createError } = this;
        const myFailureMessage = "Required";
        return notEmptyFieldsMethod(
          value,
          path,
          createError,
          myFailureMessage,
          setFirstNameError
        );
      })
      .required("required")
      .min(2, "minimum 2 characters is required"),
    middle_name: yup
      .string()
      .max(75, "maximum 75 characters is required")
      .nullable(),
    last_name: yup
      .string()
      .max(75, "maximum 75 characters is required")
      .typeError("required")
      .test("is_empty_field", function (value) {
        const { path, createError } = this;
        const myFailureMessage = "Required";
        return notEmptyFieldsMethod(
          value,
          path,
          createError,
          myFailureMessage,
          setLastNameError
        );
      })
      .required("required")
      .min(2, "minimum 2 characters is required"),
    mobile: yup
      .array()
      .of(
        yup.object().shape({
          number: yup
            .string()
            .typeError("required")
            .required("required")
            .test("is_empty_field", function (value) {
              const { path, createError } = this;
              const myFailureMessage = "Invalid phone number";
              return notEmptyFieldsMethod(
                value,
                path,
                createError,
                myFailureMessage,
                setMobileNoError
              );
            })
            .min(11, "The value is not a valid Phone Number"),
        })
      )
      .min(1, "must have atleast 1 records"),
    // .uniquePhone("You cannot add duplicate phone number"),

    DOB: yup
      .date()
      .typeError("required")
      .required("required")
      .test("validate-dob", function (value) {
        const { path, createError } = this;
        const myFailureMessage =
          "The Applicant DOB must be 18 years or more and less than 100 years old";
        try {
          if (value) {
            if (validateDOB(new Date(value))) return true;
            return createError({ path, message: myFailureMessage });
          }
          return notEmptyFieldsMethod(
            value,
            path,
            createError,
            myFailureMessage,
            setDobError
          );
        } catch (error) {
          // 
        }
      }),
    other_reason: yup.string().when("availableToUpload_id", {
      is: (value) => {
        return value == "No";
      },
      then: yup.string().when("no_doc_reason", {
        is: (value) => {
          return value == "Other";
        },
        then: yup
          .string()
          .typeError("required")
          .required("Required")
          .matches(/^$|^\S+.*/g, "Enter a valid value"),
        otherwise: yup.string().nullable(),
      }),
      otherwise: yup.string().nullable(),
    }),
    otherPhoneCaption: yup.object().nullable(),
  });


  const schema = yup.object().shape({
    applicant: applicant_data_schema,
    // identification_data: identification_data_schema,
    // address: address_data_schema,
    // ssn_data: ssn_data_schema
  });

  const methods = useForm({
    // resolver: !viewOnlyFlag && yupResolver(schema),
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  const { setValue, getValues, watch } = methods;
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userDOB, setUserDOB] = useState("");

  useEffect(() => {
    const data_form_value = methods.getValues();
    const api_data = getIdentificationData?.data;
    
    // 
    // 
    if (Object.keys(data_form_value)?.length) {
      const mapData = {
        ...api_data,
        // address: !data_form_value?.address?.length
        //   ? api_data?.address
        //   : data_form_value?.address,

        applicant: {
          ...api_data?.applicant,
          alias_status: !data_form_value?.applicant?.alias_status
            ? api_data?.applicant?.alias_status
            : data_form_value?.applicant?.alias_status,
          Alias: !data_form_value?.applicant?.Alias?.length
            ? api_data?.applicant?.Alias
            : data_form_value?.applicant?.Alias,
          DOB: !data_form_value?.applicant?.DOB
            ? api_data?.applicant?.DOB
            : data_form_value?.applicant?.DOB,
          middle_name: !data_form_value?.applicant?.middle_name
            ? api_data?.applicant?.middle_name
            : data_form_value?.applicant?.middle_name,
          first_name: !data_form_value?.applicant?.first_name
            ? api_data?.applicant?.first_name
            : data_form_value?.applicant?.first_name,
          last_name: !data_form_value?.applicant?.last_name
            ? api_data?.applicant?.last_name
            : data_form_value?.applicant?.last_name,
          mobile: !data_form_value?.applicant?.mobile?.length
            ? api_data?.applicant?.mobile
            : data_form_value?.applicant?.mobile,
          otherPhoneCaption: !data_form_value?.applicant.otherPhoneCaption
            ? api_data?.applicant?.otherPhoneCaption
            : data_form_value?.applicant?.otherPhoneCaption,
          otherPhoneNumber: !data_form_value?.applicant.otherPhoneNumber
            ? api_data?.applicant?.otherPhoneNumber
            : data_form_value?.applicant?.otherPhoneNumber,
          availableToUpload_id: !data_form_value?.applicant.availableToUpload_id
            ?.length
            ? api_data?.applicant?.availableToUpload_id
            : data_form_value?.applicant?.availableToUpload_id,
        },
        ssn_data: {
          ...api_data?.ssn_data,
        },
      };
      methods.reset(mapData);
      setUserFirstName(
        !data_form_value?.applicant?.first_name
          ? api_data?.applicant?.first_name
          : data_form_value?.applicant?.first_name
      );
      setUserLastName(
        !data_form_value?.applicant?.last_name
          ? api_data?.applicant?.last_name
          : data_form_value?.applicant?.last_name
      );
      setUserDOB(
        !data_form_value?.applicant?.DOB
          ? moment(api_data?.applicant?.DOB).format("MM/DD/YYYY")
          : moment(data_form_value?.applicant?.DOB).format("MM/DD/YYYY")
      );
    } else {
      methods.reset(api_data);
    }
  }, [getIdentificationData?.data, methods]);

  const onSubmitDetails = async (fromData: any) => {
    const tempFormData = {
      ...fromData,
      ssn_data: {
        ...fromData?.ssn_data,
        no_doc_reason:
          fromData?.ssn_data?.no_doc_reason === "Other"
            ? fromData?.ssn_data?.other_reason
            : fromData?.ssn_data?.no_doc_reason,
      },
    };
    // if (fromData?.ssn_data?.no_doc_reason === "Other") {
    //   fromData?.ssn_data?.no_doc_reason = fromData?.ssn_data?.other_reason;
    // }

    if (tempFormData?.identification_data?.no_doc_reason === "Other") {
      tempFormData.identification_data.no_doc_reason =
        tempFormData?.identification_data?.other_reason;
    }

    setSelectedName(null);
    setSelectedDOB(null);
    if (tempFormData.address?.length > 0) {
      for (let index = 0; index < tempFormData.address.length; index++) {
        tempFormData.address[index].ID = tempFormData.address[index].ID
          ? tempFormData.address[index].ID
          : 0;
      }
    }
    const payload = {
      trn_id,
      ...tempFormData,
    };
    const response = await toast.promise(saveIdDetails(payload), {
      pending: "Saving",
      error: "Saving failed 🤯",
    });
    if (response) {
      navigate("applicantDetail");
    }
  };

  // component on mount 
  // useEffect(() => {
  //   methods.reset(getIdentificationData?.data);
  // }, []);

  const onSubmit = async (formData) => {
    setOpen(false);
    if (
      getIdentificationData?.data?.identification_data?.Barcodedata[1]
        ?.field_value_original === "" &&
      getIdentificationData?.data?.identification_data?.Barcodedata[3]
        ?.field_value_original === "" &&
      getIdentificationData?.data?.identification_data?.Barcodedata[8]
        ?.field_value_original === ""
    ) {
      return onSubmitDetails(formData);
    }
    if (watch("identification_data.backfile") === null) {
      return onSubmitDetails(formData);
    }
    if (
      nameMatched?.applicantNameMatched === true &&
      nameMatched?.applicantDOBMatched === true
    ) {
      setOpen(false);
      return onSubmitDetails(formData);
    }

    if (
      nameMatched?.applicantNameMatched === false ||
      nameMatched?.applicantDOBMatched === false
    ) {
      //Beacuse we have to set both to not null but while for only DOB occur error, we are setting
      //selectDOB to "value={USERENTERED || DOCUMENTENTEREDVALUE}" but selectName remains null so
      //we use || not &&.
      if (selectedName !== null || selectedDOB !== null) {
        setOpen(false);
        return onSubmitDetails(formData);
      }
      // 
      setOpen(true);
    } else {
      onSubmitDetails(formData);
    }
  };
  //  new states
  const [expandIdSection, setExpandIdSection] = React.useState(true);

  const handleSubmitManual = () => {
    // onSubmit(methods.getValues());
    submitBtnRef?.current?.click();
  };
  const handleAPIDebounced = useMemo(() => {
    return debouce(handleSubmitManual, 3000);
  }, []);

  return (
    <>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <fieldset>
            <div className="info-toggle">
              <div className="toggle-heads">
                <div className="row align-items-center justify-content-between " role='button' onClick={() => setExpandIdSection((v) => !v)}>
                  <div className="col">
                    <div className="d-flex align-items-center">
                      <b className="ms-3 me-2">Personal Details</b>
                    </div>
                  </div>

                  <div className="col text-end m-1">
                    <IconButton
                      className="print-hide"
                      onClick={() => setExpandIdSection((v) => !v)}
                      color="primary"
                    >
                      {!expandIdSection ? <AddIcon /> : <RemoveIcon />}
                    </IconButton>
                  </div>
                </div>

                <div
                  className={` ${
                    !expandIdSection ? "d-none" : "d-display"
                  } card-body cust-card-appl`}
                >
                  {/* <form> */}
                  <div
                    className="card card-body w-100"
                    style={{ paddingTop: 0 }}
                  >
                    <>
                      <PersonalDetailSection
                        trn_id={trn_id}
                        handleAPIDebounced={handleAPIDebounced}
                      />
                      <button
                        type="submit"
                        ref={submitBtnRef}
                        className="d-none"
                      >
                        submit
                      </button>
                    </>
                  </div>
                  {/* </form> */}
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </FormProvider>
    </>
  );
};

export default PersonalDetailsAccordion;
