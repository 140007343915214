/* eslint-disable eqeqeq */
import { yupResolver } from "@hookform/resolvers/yup";
import React, {
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  useCallback,
  useState,
  useMemo,
} from "react";
import {
  DateInput,
  FormDataConsumer,
  NumberInput,
  SimpleForm,
  TextInput,
  useGetIdentity,
  useDataProvider,
  useNotify,
  useGetOne,
  useStore,
  useRefresh,
} from "react-admin";
import * as Yup from "yup";
import {
  useForm,
  FormProvider,
  useFormContext,
  useController,
} from "react-hook-form";
import moment from "moment";
// import { DevTool } from "@hookform/devtools";
import InputDateField from "../common/InputComponents/InputDateFieldOld";
import InputField2 from "../common/InputComponents/InputField2";
import RadioButtonGroupInputIds from "../common/InputComponents/RadioButtonGroupInputIds";
import ShowValidationMessages from "./components/ShowValidationMessages";
import ShowComments from "./components/ShowComments";
import ShowRawData from "./Modals/ShowRawData";
import YesNoRadioButton from "../../componets/common/InputComponents/YesNoRadioButton";
import NoDocReasonRadioButtons from "../../componets/common/InputComponents/NoDocReasonRadioButtons";
import ShowDocumentStatus from "./components/ShowDocumentStatus";
import StatusComponent from "./components/StatusComponent";
import overa from "../../assets/images/overa.svg";
import redTick from "../../assets/images/redTick.svg";
import greenTick from "../../assets/images/greenTick.svg";
import AddDocuverusComment from "./Modals/AddDocuverusComment";
import { useMutation, useQueryClient } from "react-query";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FormInstruction from "../common/FormInstruction";
import AlertDialog from "../common/AlertDialog";
import { DevTool } from "@hookform/devtools";
import FrontBackForIds from "./Modals/FrontBackForIds";
import debouce from "lodash.debounce";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import SyncIcon from "@mui/icons-material/Sync";
import { useSelector } from "react-redux";
import { RootState } from "../../Store/store";
import { JSONTree } from "react-json-tree";
import { dataProvidersAts } from "../../dataproviders/DataProvidersAts";

const ProofOfIdForm = forwardRef((props: any, ref) => {
  // const proofOfIdsData = props;

  // const role = 'applicant'
  const { identity, isLoading: identityLoading } = useGetIdentity();
  const userRole = useSelector((state: RootState) => state.users.role);

  const queryClient = useQueryClient();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [applicantValidationMessages, setApplicantValidationMessages] =
    React.useState([]);
  const [selectedDocumentId, setSelectedDocumentId] = React.useState(0);
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [frontFileError, setFrontFileError] = React.useState(false);
  const [backFileError, setBackFileError] = React.useState(false);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  useImperativeHandle(ref, () => ({ handleClickByRef, handleSubmitManual }));
  // Fix the co-applicant issue
  // const trn_id = props.trn_id; //useSelector((state: RootState) => state.users.userTrnId);
  const userTrnId = useSelector((state: RootState) => state.users.userTrnId);
  const trn_id=props?.trn_id || userTrnId;
  const { data: proofOfIdsData, isLoading: proofOfIdsDataLoading } = useGetOne(
    "proofOfIdsData",
    { id: trn_id },
    { enabled: trn_id != null, refetchOnWindowFocus: false }
  );

  const localSchema = Yup.object({
    availableToUpload_id: Yup.string()
      .required("required")
      .typeError("required"),
    documenttype_id: Yup.string().required("required").typeError("required"),
    file: Yup.string().when("availableToUpload_id", {
      is: (value) => {
        return value == "Yes";
      },
      then: Yup.string()
        .required("required")
        .typeError("Please upload front file"),
      otherwise: Yup.string().nullable(),
    }),
    // backfile: Yup.string().when("availableToUpload_id", {
    //   is: (value) => {
    //     return value == "Yes";
    //   },
    //   then: Yup.string().when("documenttype_id", {
    //     is: (value) => {
    //       return value == "2" || value == "13";
    //     },
    //     then: Yup.string()
    //       .required("required")
    //       .typeError("Please upload back file"),
    //     otherwise: Yup.string().nullable(),
    //   }),
    //   otherwise: Yup.string().nullable(),
    // }),
    other_reason: Yup.string().when(["availableToUpload_id", "no_doc_reason"], {
      is: (availableToUpload_id, no_doc_reason) => {
        return availableToUpload_id == "No" && no_doc_reason == "other";
      },
      then: Yup.string()
        .typeError("required")
        .required("Required")
        .max(5000, "maximum 5000 characters is required")
        .matches(/^$|^\S+.*/g, "Enter a valid value"),
      otherwise: Yup.string().nullable(),
    }),
    no_doc_reason: Yup.string().when("availableToUpload_id", {
      is: (value) => {
        return value == "No";
      },
      then: Yup.string().when("other_reason", {
        is: (value) => {
          return value == "";
        },
        then: Yup.string().typeError("required").required("Required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
  });
  async function handleClickByRef() {
    submitButtonRef?.current?.click();
    const isValidForm = await methods.trigger();

    return isValidForm;
  }

  const schema = Yup.object({
    availableToUpload_id: Yup.string()
      .required("required")
      .typeError("required"),
    first_name: Yup.string().when("availableToUpload_id", {
      is: (value) => {
        return value == "Yes";
      },
      then: Yup.string()
        .typeError("required")
        .required("required")
        .max(75, "maximum 75 characters is required")
        .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "invalid"),
      otherwise: Yup.string().nullable(),
    }),
    middle_name: Yup.string()
      .nullable()
      .max(75, "maximum 75 characters is required")
      .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "invalid"),
    last_name: Yup.string().when("availableToUpload_id", {
      is: (value) => {
        return value == "Yes";
      },
      then: Yup.string()
        .max(75, "maximum 75 characters is required")
        .typeError("required")
        .required("required")
        .matches(/^[A-Za-z0-9_ ,.'-]+$|^$/i, "invalid"),
      otherwise: Yup.string().nullable(),
    }),
    DOB: Yup.date().when("availableToUpload_id", {
      is: (value) => {
        return value == "Yes";
      },
      then: Yup.date().typeError("required").required("required"),
      otherwise: Yup.date().nullable(),
    }),
    expiration_date: Yup.date().when("availableToUpload_id", {
      is: (value) => {
        return value == "Yes";
      },
      then: Yup.date().typeError("required").required("required"),
      otherwise: Yup.date().nullable(),
    }),
    city: Yup.string().when("documenttype_id", {
      is: (value) => {
        return value == "2" || value == "13";
      },
      then: Yup.string().when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: Yup.string()
          .nullable()
          .required("required")
          .min(2, "minimum 2 characters is required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    state: Yup.string().when("documenttype_id", {
      is: (value) => {
        return value == "2" || value == "13";
      },
      then: Yup.string().when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: Yup.string()
          .nullable()
          .required("required")
          .min(2, "minimum 2 characters is required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    zipcode: Yup.string().when("documenttype_id", {
      is: (value) => {
        return value == "2" || value == "13";
      },
      then: Yup.string().when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: Yup.string()
          .nullable()
          .typeError("required")
          .matches(
            /^(\d{4}[0-9]|\d{8}[0-9]|\d{4}[0-9]-\d{3}[0-9]|-----\d{3}[0-9])$/g,
            "Please enter valid zip code"
          )
          .required("required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    street_address: Yup.string().when("documenttype_id", {
      is: (value) => {
        return value == "2" || value == "13";
      },
      then: Yup.string().when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: Yup.string()
          .nullable()
          .required("required")
          .min(2, "minimum 2 characters is required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    license_id: Yup.string().when("documenttype_id", {
      is: (value) => {
        return value == "2" || value == "13";
      },
      then: Yup.string().when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: Yup.string().typeError("required").required("required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    passport_no: Yup.string().when("documenttype_id", {
      is: (value) => {
        return value == "1";
      },
      then: Yup.string().when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: Yup.string().typeError("required").required("required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    uscis_number: Yup.string().when("documenttype_id", {
      is: (value) => {
        return value == "10";
      },
      then: Yup.string().when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: Yup.string().typeError("required").required("required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    controller_number: Yup.string().when("documenttype_id", {
      is: (value) => {
        return value == "11";
      },
      then: Yup.string().when("availableToUpload_id", {
        is: (value) => {
          return value == "Yes";
        },
        then: Yup.string().typeError("required").required("required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    other_reason: Yup.string().when("availableToUpload_id", {
      is: (value) => {
        return value == "No";
      },
      then: Yup.string().when("no_doc_reason", {
        is: (value) => {
          return value == "other";
        },
        then: Yup.string().typeError("required").required("Required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
    no_doc_reason: Yup.string().when("availableToUpload_id", {
      is: (value) => {
        return value == "No";
      },
      then: Yup.string().when("other_reason", {
        is: (value) => {
          return value == "";
        },
        then: Yup.string().typeError("required").required("Required"),
        otherwise: Yup.string().nullable(),
      }),
      otherwise: Yup.string().nullable(),
    }),
  });

  const schemaNotRequired = Yup.object({
    availableToUpload_id: Yup.string().nullable(),
  });
  const getSchema = () => {
    if (userRole == "Docuverus Super Admin") return schemaNotRequired;
    if (props.required) {
      if (userRole == "applicant") return localSchema;
      else return schema;
    }
    return schemaNotRequired;
  };

  const methods = useForm<any>({
    resolver: yupResolver(getSchema()),
    mode: "onBlur",
    defaultValues: {
      documenttype_id: "2",
      BarcodeDocumentID: null,
      application_document_id: null,
      availableToUpload_id: "",
      SOCRStatus: null,
      no_doc_reason:
        "I don't currently have access to document(s), but can provide later",
      other_reason: "",
      file: null,
      backfile: null,
      frontOCR: null,
      backOCR: null,
      section_comment: null,
    },
  });

  React.useEffect(() => {
    const firstError = Object.keys(methods.formState.errors).map(
      (feild) => feild
    );
    //
    if (firstError.includes("file")) {
      setFrontFileError(true);
    } else {
      setFrontFileError(false);
    }

    if (
      (selectedDocumentId == 2 || selectedDocumentId == 13) &&
      firstError.includes("backfile")
    ) {
      setBackFileError(true);
    } else {
      setBackFileError(false);
    }
  }, [methods.formState.errors]);

  const handleSubmitManual = () => {
    if (userRole === "applicant") {
      submitButtonRef?.current?.click();
    } else {
      onSubmit(methods.getValues());
    }
  };
  const handleAPIDebounced = useMemo(() => {
    return debouce(handleSubmitManual, 3000);
  }, []);

  // const handleAPIDebounced = useCallback(
  //   debounce(
  //     (nextValue) =>
  //       (async () => {
  //         handleSubmitManual()
  //       })(),
  //     1000
  //   ),
  //   [] // will be created only once initially
  // );
  useEffect(() => {
    // checking random keys
    if (proofOfIdsData?.identification_data) {
      const formValue: any = methods.getValues();
      const availableUploadCheck = formValue.availableToUpload_id
        ? formValue.availableToUpload_id
        : proofOfIdsData?.identification_data?.availableToUpload_id
        ? proofOfIdsData?.identification_data?.availableToUpload_id
        : props.status == 1
        ? "Yes"
        : null;
      const defaultData = {
        ...proofOfIdsData?.identification_data,
        availableToUpload_id: availableUploadCheck,
        no_doc_reason: proofOfIdsData?.identification_data?.no_doc_reason
          ? proofOfIdsData?.identification_data?.no_doc_reason
          : formValue?.no_doc_reason
          ? formValue?.no_doc_reason
          : "I do not currently have access to document(s), but can provide later",
        city: proofOfIdsData?.identification_data.city,
        controller_number:
          proofOfIdsData?.identification_data.controller_number,
        DOB: proofOfIdsData?.identification_data.DOB,
        expiration_date: proofOfIdsData?.identification_data.expiration_date,
        first_name: proofOfIdsData?.identification_data.first_name,
        last_name: proofOfIdsData?.identification_data.last_name,
        license_id: proofOfIdsData?.identification_data?.license_id,
        middle_name: proofOfIdsData?.identification_data.middle_name,
        passport_no: proofOfIdsData?.identification_data.passport_no,
        state: proofOfIdsData?.identification_data.state,
        street_address: proofOfIdsData?.identification_data.street_address,
        uscis_number: proofOfIdsData?.identification_data.uscis_number,
        zipcode: proofOfIdsData?.identification_data.zipcode,
        documenttype_id:
          proofOfIdsData?.identification_data.documenttype_id ||
          formValue.documenttype_id,
      };
      methods.reset(defaultData);
    }
  }, [proofOfIdsData?.identification_data]);

  const getValidationMessageObject = (role, fieldData) => {
    if (role == "applicant") {
      return fieldData?.validation_message?.map((message) => ({
        ...message.applicant,
      }));
    } else {
      return fieldData?.validation_message?.map((message) => ({
        ...message.admin,
      }));
    }
  };

  const getSrcfromStatus = (socrValue) => {
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return greenTick;
        case 2:
          return redTick;
        case 3:
          return overa;
      }
    } else {
      return overa;
    }
  };

  const getBlockStyleFromStatus = (socrValue) => {
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "p-1 block text-center rounded bg-success text-white";
        case 2:
          return "p-1 block text-center rounded bg-danger text-white";
        case 3:
          return "p-1 block text-center rounded bg-warning text-dark";
      }
    } else {
      return "p-1 block text-center rounded bg-warning text-dark";
    }
  };

  const getClassfromStatus = (socrValue) => {
    return null;
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "green-stat";
        case 2:
          return "red-stat";
        case 3:
          return "brown-stat";
      }
    } else {
      return "brown-stat";
    }
  };

  const gettextStylefromStatus = (socrValue) => {
    return "";
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "green-ltr";
        case 2:
          return "red-ltr";
        case 3:
          return "brown-ltr";
      }
    } else {
      return "brown-ltr";
    }
  };

  const validationText = (socrValue) => {
    if (socrValue) {
      switch (Number(socrValue)) {
        case 1:
          return "Valid";
        case 2:
          return "Unverifiable";
        case 3:
          return "Verification Required";
      }
    } else {
      return "Verification Required";
    }
  };

  const { mutate, isLoading, isSuccess } = useMutation(
    "updateProofofID",
    (data: any) =>
      dataProvider.update("applicant", {
        id: data.trn_id,
        data: data.data,
        previousData: data.previousData,
      }),
    {
      onSuccess: (response) => {
        setConfirmDelete(false);
        // if (userRole == "applicant") {
        if (methods.getValues("availableToUpload_id") == "No")
          queryClient.invalidateQueries(["proofOfIdsData"]);
        // }
      },
      onError: (data) => {
        setConfirmDelete(false);
        alert("error");
      },
      onMutate: () => {
        notify("Updating details...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const onSubmitEmpty = (val) => null;

  const onSubmit = (value) => {
    if (value.availableToUpload_id === "Yes") {
      const payload = {
        ID: value?.ID,
        document_id: value?.documenttype_id,
        SOCRStatus: null,
        application_id: value?.application_id,
        subscriber_id: value?.subscriber_id,
        applicant_id: value?.applicant_id,
        SOCRComment: null,
        files: {
          application_document_id: value?.application_document_id,
        },
        noDocReason: null,
        ocr_data: {
          first_name: value.first_name,
          middle_name: value.middle_name,
          last_name: value.last_name,
          dob: value.DOB,
          issue_date: value?.issue_date,
          expiration_date: value.expiration_date,
          doc_state_type: null,
          no_doc_reason: null,
          no_doc_flag: false,
        },
        subUrl: "/saveIdentityInfo",
        trn_id: trn_id,
        availableToUpload_id: "Yes",
      };
      switch (value.documenttype_id) {
        case "2": {
          payload.ocr_data["address"] = value.street_address;
          payload.ocr_data["state"] = value.state;
          payload.ocr_data["postal_code"] = value.zipcode;
          payload.ocr_data["city"] = value.city;
          payload.ocr_data["license_id"] = value?.license_id;
          payload.ocr_data["barcode_document_id"] = value?.BarcodeDocumentID;
          payload["back_files"] = {
            application_document_id: value?.BarcodeDocumentID,
          };
          break;
        }
        case "13": {
          payload.ocr_data["address"] = value.street_address;
          payload.ocr_data["state"] = value.state;
          payload.ocr_data["postal_code"] = value.zipcode;
          payload.ocr_data["city"] = value.city;
          payload.ocr_data["license_id"] = value?.license_id;
          payload.ocr_data["barcode_document_id"] = value?.BarcodeDocumentID;
          payload["back_files"] = {
            application_document_id: value?.BarcodeDocumentID,
          };
          break;
        }
        case "1": {
          payload.ocr_data["passport_no"] = value.passport_no;
          break;
        }
        case "10": {
          payload.ocr_data["uscis_number"] = value.uscis_number;
          break;
        }
        case "11": {
          payload.ocr_data["controller_number"] = value.controller_number;
          break;
        }
      }
      mutate({
        trn_id: trn_id,
        data: payload,
        previousData: value,
      });
    } else {
      const final_paylaod = {
        ID: value?.ID,
        document_id: value?.documenttype_id,
        application_id: value?.application_id,
        subscriber_id: value?.subscriber_id,
        applicant_id: value?.applicant_id,
        files: {
          application_document_id: null,
        },
        back_files: {
          application_document_id: null,
        },
        application_document_id: "0",
        noDocReason:
          (value.no_doc_reason !==
            "I don't currently have access to document(s), but can provide later" &&
            value.no_doc_reason !== "I do not have proof of identification") ||
          value.no_doc_reason === "other"
            ? value.other_reason
            : value.no_doc_reason,
        ocr_data: {
          first_name: null,
          middle_name: null,
          last_name: null,
          dob: null,
          issue_date: null,
          expiration_date: null,
          doc_state_type: null,
          no_doc_reason: null,
          no_doc_flag: false,
        },
        subUrl: "/saveIdentityInfo",
        trn_id: trn_id,
        availableToUpload_id: "No",
      };
      mutate({
        trn_id: trn_id,
        data: final_paylaod,
        previousData: value,
      });
    }
  };

  const {
    mutate: deleteFiles,
    isLoading: loadingdeleteFiles,
    isSuccess: loadingdeleteFilesSucess,
  } = useMutation(
    "deleteFile_no_doc_reason",
    (data: any) => {
      let promises: any = [];
      data.map((id) => promises.push(dataProvidersAts.deleteDocument(id)));
      return Promise.all(promises);
    },
    {
      onSuccess: (data) => {
        setConfirmDelete(false);
        notify("Proof of id deleted successfully...", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (data) => {
        setConfirmDelete(false);
        alert("Fail deleting Proof of id ");
      },
      onMutate: () => {
        notify("Document is Deleting...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const { mutate: deleteFilesAll } = useMutation(
    "deleteFile_no_doc_reason",
    (data: any) => {
      let promises: any = [];
      data.map((id) =>
        promises.push(dataProvidersAts.deleteDocumentAllIds(id))
      );
      return Promise.all(promises);
    },
    {
      onSuccess: (data) => {
        setConfirmDelete(false);
        notify("Proof of id deleted successfully...", {
          type: "success",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
      onError: (data) => {
        setConfirmDelete(false);
        alert("Fail deleting Proof of id ");
      },
      onMutate: () => {
        notify("Document is Deleting...", {
          type: "info",
          anchorOrigin: { vertical: "top", horizontal: "right" },
        });
      },
    }
  );

  const handleSubmitNoDocReason = (values) => {
    let id_s: any = [];
    if (values.application_document_id) {
      id_s.push(values.application_document_id);
    }
    // if (values.BarcodeDocumentID) {
    //   id_s.push(values.BarcodeDocumentID);
    // }
    deleteFilesAll(id_s);
    // TODO NO DOC reasons save
    handleAPIDebounced();
  };
  const [expandForDetails, setExpandForDetails] = React.useState(false);
  const [fileUploading, setfileUploading] = useStore(
    `fileupload_proof_of_id_loading`
  );
  // TODO:
  // 1. if no file present and role is not applicant collapse true
  // 2. if role is applicant collapse false
  const [expandIdSection, setExpandIdSection] = React.useState(false);

  useEffect(() => {
    if (userRole === "applicant") setExpandIdSection(true);
  }, [userRole]);

  useEffect(() => {
    if (methods.watch("availableToUpload_id") === "Yes")
      setExpandIdSection(true);
  }, [methods.watch("availableToUpload_id")]);
  const refresh = useRefresh();

  return (
    <>
      {
        <FormProvider {...methods}>
          <fieldset disabled={isLoading || fileUploading}>
            <div className="info-toggle">
              <div className="toggle-heads">
                <div className="row align-items-center justify-content-between ">
                  <div className="col">
                    <div className="d-flex align-items-center">
                      {userRole === "Docuverus Super Admin" && (
                        <FormDataConsumer>
                          {({ formData, ...rest }) => {
                            if (formData?.raw_data)
                              return (
                                <>
                                  <ShowRawData
                                    title={"PDF RAW data"}
                                    rawData={formData?.raw_data}
                                  />
                                </>
                              );
                            else return <></>;
                          }}
                        </FormDataConsumer>
                      )}
                      <b className="ms-3 me-2">ID</b>
                      <AlertDialog
                        message={
                          "Please upload CLEAR and straight images of your proof of identification. For state driver’s licenses or ID’s, both the front and back of your ID are required, and the back barcode must be VERY CLEAR. If you don’t currently have a clear image(s) of your ID, then you can scan or click the scan ID QR code; this will allow you to use your mobile phone as a remote scanner."
                        }
                      />
                      <div className="subcontent p-1 pb-t10">
                        <div className="upload-subwrap">
                          {userRole === "applicant" && (
                            <p className="prf-upload">
                              <strong style={{ color: "#FF8585" }}>
                                Note :{" "}
                              </strong>
                              Upload Proof of ID Here
                            </p>
                          )}
                          {userRole !== "applicant" &&
                            methods.watch("availableToUpload_id") !== "Yes" && (
                              <p className="prf-upload">
                                <strong style={{ color: "#FF8585" }}>
                                  Note :{" "}
                                </strong>
                                No Documents uploaded
                              </p>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col text-end m-1">
                    {userRole !== "applicant" && (
                      <IconButton
                        className="print-hide"
                        onClick={() => setExpandIdSection((v) => !v)}
                        color="primary"
                      >
                        {!expandIdSection ? <AddIcon /> : <RemoveIcon />}
                      </IconButton>
                    )}
                  </div>
                </div>

                <div
                  className={` ${
                    !expandIdSection ? "d-none" : "d-display"
                  } card-body cust-card-appl`}
                >
                  <form
                    onSubmit={methods.handleSubmit(
                      userRole == "applicant" ? onSubmit : onSubmit
                    )}
                  >
                    <div
                      className="card card-body w-100"
                      style={{ paddingTop: 0 }}
                    >
                      <YesNoRadioButton
                        required={props.required}
                        label="Do you have a proof of identity available? "
                        source="availableToUpload_id"
                        disable={false}
                        handleOnClick={(val) => {
                          if (
                            val === "No" &&
                            (methods.watch("file") || methods.watch("backfile"))
                          ) {
                            setConfirmDelete(true);
                          }
                        }}
                      />
                      <FormDataConsumer>
                        {({ formData, ...rest }) => {
                          {
                            /* This flow is if Yes is selected  */
                          }
                          if (formData.availableToUpload_id === "Yes")
                            return (
                              <>
                                {(formData?.file || formData?.backfile) &&
                                  userRole != "applicant" && (
                                    <>
                                      {formData?.frontOCRCompleted &&
                                      (formData?.backfile != null
                                        ? formData?.backOCRCompleted
                                        : true) ? (
                                        <div className="appl-status-wrap align-items-start">
                                          <div className="d-flex flex-wrap">
                                            {userRole ===
                                            "Docuverus Super Admin" ? (
                                              <div className="pr-2">
                                                <ShowDocumentStatus
                                                  className={
                                                    "col-lg col-sm-6 col-6 sub-aapl"
                                                  }
                                                  trn_id={trn_id}
                                                  documentId={
                                                    selectedDocumentId
                                                  }
                                                  SourceId={1}
                                                  value={
                                                    formData?.ManualSOCRstatus
                                                      ? formData?.ManualSOCRstatus
                                                      : formData?.CalculatedSOCRStatus
                                                  }
                                                  src={getSrcfromStatus(
                                                    formData?.ManualSOCRstatus
                                                      ? formData?.ManualSOCRstatus
                                                      : formData?.CalculatedSOCRStatus
                                                  )}
                                                  blockStyle={getBlockStyleFromStatus(
                                                    formData?.ManualSOCRstatus
                                                      ? formData?.ManualSOCRstatus
                                                      : formData?.CalculatedSOCRStatus
                                                  )}
                                                  textStyle={gettextStylefromStatus(
                                                    formData?.ManualSOCRstatus
                                                      ? formData?.ManualSOCRstatus
                                                      : formData?.CalculatedSOCRStatus
                                                  )}
                                                  // fetchRecord={() => null}
                                                  label={"Overall"}
                                                  invalidateQueries={
                                                    "proofOfIdsData"
                                                  }
                                                />
                                              </div>
                                            ) : (
                                              <div className="pr-2">
                                                <ShowDocumentStatus
                                                  customWidth={150}
                                                  className={
                                                    "col-lg col-sm-6 col-6 sub-aapl"
                                                  }
                                                  trn_id={trn_id}
                                                  documentId={
                                                    selectedDocumentId
                                                  }
                                                  SourceId={1}
                                                  value={
                                                    formData?.ManualSOCRstatus
                                                      ? formData?.ManualSOCRstatus
                                                      : formData?.CalculatedSOCRStatus
                                                  }
                                                  src={getSrcfromStatus(
                                                    formData?.ManualSOCRstatus
                                                      ? formData?.ManualSOCRstatus
                                                      : formData?.CalculatedSOCRStatus
                                                  )}
                                                  blockStyle={getBlockStyleFromStatus(
                                                    formData?.ManualSOCRstatus
                                                      ? formData?.ManualSOCRstatus
                                                      : formData?.CalculatedSOCRStatus
                                                  )}
                                                  textStyle={gettextStylefromStatus(
                                                    formData?.ManualSOCRstatus
                                                      ? formData?.ManualSOCRstatus
                                                      : formData?.CalculatedSOCRStatus
                                                  )}
                                                  disable={true}
                                                  label={"Overall"}
                                                  invalidateQueries={
                                                    "proofOfIdsData"
                                                  }
                                                />
                                              </div>
                                            )}

                                            <div className="px-2">
                                              <StatusComponent
                                                blockStyle={""}
                                                label={"Name Matched"}
                                                validationIcon={
                                                  formData?.name_match
                                                    ? greenTick
                                                    : overa
                                                }
                                                validationText={
                                                  formData?.name_match
                                                    ? "Yes"
                                                    : "No"
                                                }
                                                validationTextStyle={
                                                  formData?.name_match
                                                    ? "green-stat"
                                                    : "brown-stat"
                                                }
                                              />
                                            </div>
                                            <div className="px-2">
                                              <StatusComponent
                                                blockStyle={""}
                                                label={"Front"}
                                                validationIcon={
                                                  formData?.file
                                                    ? greenTick
                                                    : overa
                                                }
                                                validationText={
                                                  formData?.file ? "Yes" : "No"
                                                }
                                                validationTextStyle={
                                                  formData?.file
                                                    ? "green-stat"
                                                    : "brown-stat"
                                                }
                                              />
                                            </div>
                                            {formData?.documenttype_id ===
                                              "2" ||
                                            formData?.documenttype_id ===
                                              "13" ? (
                                              <>
                                                <div className="px-2">
                                                  <StatusComponent
                                                    blockStyle={""}
                                                    label={"Back"}
                                                    validationIcon={
                                                      formData?.backfile
                                                        ? greenTick
                                                        : overa
                                                    }
                                                    validationText={
                                                      formData?.backfile
                                                        ? "Yes"
                                                        : "No"
                                                    }
                                                    validationTextStyle={
                                                      formData?.backfile
                                                        ? "green-stat"
                                                        : "brown-stat"
                                                    }
                                                  />
                                                </div>
                                                <div className="px-2">
                                                  <StatusComponent
                                                    blockStyle={""}
                                                    label={"Front/back Match"}
                                                    validationIcon={
                                                      formData?.percentage > 70
                                                        ? greenTick
                                                        : overa
                                                    }
                                                    validationText={
                                                      formData?.percentage > 70
                                                        ? "Yes"
                                                        : "No"
                                                    }
                                                    validationTextStyle={
                                                      formData?.percentage > 70
                                                        ? "green-stat"
                                                        : "brown-stat"
                                                    }
                                                  />
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div className="px-2">
                                                  <StatusComponent
                                                    blockStyle={""}
                                                    label={"Back"}
                                                    validationIcon={""}
                                                    validationText={"NA"}
                                                    validationTextStyle={
                                                      "brown-stat"
                                                    }
                                                  />
                                                </div>
                                                <div className="px-2">
                                                  <StatusComponent
                                                    blockStyle={""}
                                                    label={"Front/back Match"}
                                                    validationIcon={""}
                                                    validationText={"NA"}
                                                    validationTextStyle={
                                                      "brown-stat"
                                                    }
                                                  />
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="d-flex align-item-center">
                                          <FormInstruction
                                            message={
                                              "Document under Processing"
                                            }
                                          />
                                          <button
                                            type="button"
                                            onClick={() => refresh()}
                                            className="btn btn-sm btn-outline-primary"
                                          >
                                            <SyncIcon sx={{ fontSize: 12 }} />{" "}
                                            Refresh
                                          </button>
                                        </div>
                                      )}
                                    </>
                                  )}
                                <ShowValidationMessages
                                  role={userRole}
                                  validation_message={getValidationMessageObject(
                                    userRole,
                                    formData
                                  )}
                                  fetchRecord={() => null}
                                  invalidateQueries={"proofOfIdsData"}
                                  section={"Proof of ID"}
                                  tag={`updateValidationMessages_pid`}
                                />
                                {(methods.watch("file") != null ||
                                  methods.watch("backfile") != null) && (
                                  <ShowComments
                                    section_comment={formData.section_comment}
                                    role={userRole}
                                  />
                                )}
                                <div
                                  className="radio-select mt-1 row"
                                  style={{
                                    boxShadow: "none",
                                    background: "#f5fdff",
                                  }}
                                >
                                  <div className="login-radio-types">
                                    <FormInstruction
                                      message={
                                        "Please upload CLEAR images of your ID here. Click the “i” for details."
                                      }
                                    />

                                    <RadioButtonGroupInputIds
                                      setSelectedDocumentId={(val) =>
                                        setSelectedDocumentId(val)
                                      }
                                      source={"documenttype_id"}
                                      label="documentType"
                                      frontFileError={frontFileError}
                                      backFileError={backFileError}
                                      Trnid={trn_id}
                                    />
                                  </div>

                                  {userRole != "applicant" && (
                                    <>
                                      <div className="expand-heads d-flex print-hide">
                                        <button
                                          className="ml-auto print-hide"
                                          type="button"
                                          onClick={(e) =>
                                            setExpandForDetails((v) => !v)
                                          }
                                          // data-bs-toggle="collapse"
                                          // data-bs-target={`#app-det-coll_proof_of_id`}
                                          // aria-expanded="false"
                                          // aria-controls={`app-det-coll_proof_of_id`}
                                        >
                                          Expand for Details
                                          {expandForDetails ? (
                                            <span>
                                              <svg
                                                width="10"
                                                height="6"
                                                viewBox="0 0 10 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M5.00001 2.21856L1.70001 5.51856L0.757341 4.57589L5.00001 0.333222L9.24268 4.57589L8.30001 5.51855L5.00001 2.21856Z"
                                                  fill="#2DC3E8"
                                                />
                                              </svg>
                                            </span>
                                          ) : (
                                            <span>
                                              <svg
                                                width="10"
                                                height="6"
                                                viewBox="0 0 10 6"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M4.99999 3.78047L8.29999 0.480469L9.24266 1.42314L4.99999 5.6658L0.757324 1.42314L1.69999 0.480469L4.99999 3.78047Z"
                                                  fill="#2DC3E8"
                                                />
                                              </svg>
                                            </span>
                                          )}
                                        </button>
                                      </div>
                                      <div
                                        className="collapse cust-coll-appl print-hide"
                                        style={
                                          expandForDetails
                                            ? { display: "block" }
                                            : { display: "none" }
                                        }
                                      >
                                        <div className="new-form-wrap">
                                          <div className="row add-md-form mr-t10">
                                            {userRole !== "applicant" && (
                                              <div className="col-12 ">
                                                <div className="d-flex justify-content-end">
                                                  {formData.frontOCR &&
                                                    formData.backOCR && (
                                                      <FrontBackForIds
                                                        title={"Data"}
                                                        data={formData}
                                                      />
                                                    )}
                                                </div>
                                              </div>
                                            )}
                                            <div className="col-12 col-md-4 col-lg-4">
                                              <InputField2
                                                notRequired={true}
                                                handleOnChange={() => {
                                                  handleAPIDebounced();
                                                }}
                                                source="first_name"
                                                label={"First Name"}
                                                placeholder="First Name"
                                              />
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                              <InputField2
                                                notRequired={true}
                                                handleOnChange={() => {
                                                  handleAPIDebounced();
                                                }}
                                                source="middle_name"
                                                label={"Middle Name"}
                                                placeholder="Middle Name"
                                              />
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                              <InputField2
                                                notRequired={true}
                                                handleOnChange={() => {
                                                  handleAPIDebounced();
                                                }}
                                                source="last_name"
                                                label={"Last Name"}
                                                placeholder="Last Name"
                                              />
                                            </div>
                                            <FormDataConsumer>
                                              {({ formData, ...rest }) => {
                                                if (
                                                  formData.documenttype_id ==
                                                  "1"
                                                )
                                                  return (
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                      <InputField2
                                                        notRequired={true}
                                                        handleOnChange={() => {
                                                          handleAPIDebounced();
                                                        }}
                                                        source="passport_no"
                                                        label={
                                                          "Passport Number"
                                                        }
                                                        placeholder="Passport Number"
                                                      />
                                                    </div>
                                                  );
                                                if (
                                                  formData.documenttype_id ==
                                                  "10"
                                                )
                                                  return (
                                                    <div className="col-12 col-md-4 col-lg-4">
                                                      <InputField2
                                                        notRequired={true}
                                                        handleOnChange={() => {
                                                          handleAPIDebounced();
                                                        }}
                                                        source="uscis_number"
                                                        label={
                                                          "Permanant Residence ID"
                                                        }
                                                        placeholder="Permanant Residence ID"
                                                      />
                                                    </div>
                                                  );

                                                if (
                                                  formData.documenttype_id ==
                                                  "11"
                                                )
                                                  return (
                                                    <div className="col-12 col-md-4 col-lg-4 ">
                                                      <InputField2
                                                        notRequired={true}
                                                        handleOnChange={() => {
                                                          handleAPIDebounced();
                                                        }}
                                                        source="controller_number"
                                                        label={"Visa no"}
                                                        placeholder="Visa no"
                                                      />
                                                    </div>
                                                  );
                                                return (
                                                  <div className="col-12 col-md-4 col-lg-4">
                                                    <InputField2
                                                      notRequired={true}
                                                      handleOnChange={() => {
                                                        handleAPIDebounced();
                                                      }}
                                                      source="license_id"
                                                      label={"License/State ID"}
                                                      placeholder="License Id"
                                                    />
                                                  </div>
                                                );
                                              }}
                                            </FormDataConsumer>

                                            <div className="col-12 col-md-4 col-lg-4">
                                              <InputDateField
                                                notRequired={true}
                                                onChangeCallback={() => {
                                                  handleAPIDebounced();
                                                }}
                                                source={"DOB"}
                                                label="DOB"
                                              />
                                              {/* <TextInput source="DOB" focused variant='outlined' fullWidth /> */}
                                            </div>
                                            <div className="col-12 col-md-4 col-lg-4">
                                              <InputDateField
                                                notRequired={true}
                                                onChangeCallback={() => {
                                                  handleAPIDebounced();
                                                }}
                                                source={"expiration_date"}
                                                label="Expiration Date"
                                              />
                                            </div>
                                            {(formData.documenttype_id == "2" ||
                                              formData.documenttype_id ==
                                                "13") && (
                                              <>
                                                <div className="col-12">
                                                  <InputField2
                                                    notRequired={true}
                                                    handleOnChange={() => {
                                                      handleAPIDebounced();
                                                    }}
                                                    source="street_address"
                                                    label={"Street Address"}
                                                    placeholder="Street Address"
                                                  />
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                  <InputField2
                                                    notRequired={true}
                                                    handleOnChange={() => {
                                                      handleAPIDebounced();
                                                    }}
                                                    source="city"
                                                    label={"City"}
                                                    placeholder="City"
                                                  />
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                  <InputField2
                                                    notRequired={true}
                                                    handleOnChange={() => {
                                                      handleAPIDebounced();
                                                    }}
                                                    source="state"
                                                    label={"State"}
                                                    placeholder="State"
                                                  />
                                                </div>
                                                <div className="col-12 col-md-4 col-lg-4">
                                                  <InputField2
                                                    notRequired={true}
                                                    handleOnChange={() => {
                                                      handleAPIDebounced();
                                                    }}
                                                    source="zipcode"
                                                    label={"Zipcode"}
                                                    placeholder="Zipcode"
                                                  />
                                                </div>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            );
                          if (formData.availableToUpload_id === "No")
                            return (
                              <>
                                <div className="login-radio-types">
                                  <NoDocReasonRadioButtons
                                    handleOnchange={() => {
                                      handleAPIDebounced();
                                    }}
                                    source="no_doc_reason"
                                    options={[
                                      {
                                        name: "I don't currently have access to document(s), but can provide later",
                                        value:
                                          "I don't currently have access to document(s), but can provide later",
                                      },
                                      {
                                        name: "I do not have proof of identification",
                                        value:
                                          "I do not have proof of identification",
                                      },
                                      {
                                        name: "Other",
                                        value: "other",
                                      },
                                    ]}
                                  />
                                  {(formData.no_doc_reason !==
                                    "I don't currently have access to document(s), but can provide later" &&
                                    formData.no_doc_reason !==
                                      "I do not have proof of identification") ||
                                  formData.no_doc_reason === "other" ? (
                                    <div className="col-4 mr-t20 mr-l30 mr-b10">
                                      <InputField2
                                        handleOnChange={() =>
                                          handleAPIDebounced()
                                        }
                                        source="other_reason"
                                        label={"Other reason"}
                                        placeholder="reason"
                                        labelRequired={userRole === "applicant"}
                                      />
                                    </div>
                                  ) : null}
                                </div>
                              </>
                            );
                        }}
                      </FormDataConsumer>
                    </div>
                    {userRole === "applicant" && (
                      <button
                        type="submit"
                        ref={submitButtonRef}
                        className="btn btn-primary"
                        style={{ display: "none" }}
                      >
                        {" "}
                        Save
                      </button>
                    )}
                    <div className="d-flex flex-wrap justify-content-between pb-2 print-hide">
                      <div className="">
                        {methods.watch("availableToUpload_id") &&
                          userRole != "applicant" && (
                            <button
                              type="submit"
                              className="btn btn-primary print-hide"
                              style={{
                                backgroundColor: "#2dc3e8",
                                fontFamily: "OpenSans-Bold",
                                color: "white",
                                marginLeft: 18,
                                padding: "8px 15px 8px 15px",
                              }}
                            >
                              {" "}
                              Save
                            </button>
                          )}
                      </div>

                      <div className="px-2 print-hide">
                        {(methods.watch("file") != null ||
                          methods.watch("backfile") != null) &&
                          methods.watch("availableToUpload_id") &&
                          methods.watch("availableToUpload_id") === "Yes" && (
                            <AddDocuverusComment
                              label={
                                userRole == "applicant"
                                  ? `Add Comment`
                                  : "Add/Edit Docuverus Comment"
                              }
                              title={
                                userRole == "applicant"
                                  ? `Add Comment`
                                  : "Add/Edit Docuverus Comment"
                              }
                              defaultValue={
                                userRole == "applicant"
                                  ? methods.watch()?.section_comment?.applicant
                                  : methods.watch()?.section_comment?.admin
                              }
                              trn_id={trn_id}
                              is_applicant={
                                userRole == "applicant" ? true : false
                              }
                              document_id={selectedDocumentId}
                              tag={"addComment_applicant"}
                              invalidateQueries={"proofOfIdsData"}
                              emp_id={null}
                              role={
                                userRole == "applicant" ? "applicant" : userRole
                              }
                            />
                          )}
                      </div>
                    </div>
                  </form>
                  <DevTool control={methods.control} />
                  <Dialog
                    open={confirmDelete}
                    onClose={() => {
                      methods.setValue("availableToUpload_id", "Yes");
                      setConfirmDelete(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {`Are you sure want to delete Proof of id documents ?`}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Warning! Once deleted cannot revert back .
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <button
                        className="btn btn-info text-light"
                        onClick={() => {
                          methods.setValue("availableToUpload_id", "Yes");
                          setConfirmDelete(false);
                        }}
                      >
                        No
                      </button>
                      <button
                        className="btn btn-info text-light"
                        onClick={() => {
                          const values = methods.getValues();
                          handleSubmitNoDocReason(values);
                        }}
                      >
                        Yes
                      </button>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
            </div>
          </fieldset>
          {/* <DevTool control={methods.control} /> */}
        </FormProvider>
      }
    </>
  );
});

export default ProofOfIdForm;
