import React, { Suspense, useContext, useEffect } from 'react';
import '../../App.css';
import {
    ListBase,
    Title,
    ListToolbar,
    Pagination, Button, ChipField, CustomRoutes, Datagrid, DateField, defaultTheme, DeleteButton, FunctionField, List, ListGuesser, NumberField, Resource, SearchInput, TextField, TextInput, useDataProvider, useGetList, useGetOne, useListContext, useRecordContext,
} from 'react-admin';
import { Box, ToggleButton, ToggleButtonGroup, Button as MuiButton } from '@mui/material';
import { NumericFormat } from 'react-number-format';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import FilterModel from './FilterModel';
import { TableHead, TableRow, TableCell, Checkbox } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { DatagridBody, RecordContextProvider } from 'react-admin';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Collapse from '@mui/material/Collapse';
import { useStore } from 'react-admin';
import WarningIcon from '@mui/icons-material/Warning';
import { GetsubscriberinboxListDetails } from './GetsubscriberinboxListDetails';
import Avatar from '@mui/material/Avatar';
import Archive from './Archive';
import Paper from '@mui/material/Paper';
import NextPlanOutlinedIcon from '@mui/icons-material/NextPlanOutlined';
import TransferApp from './TransferApp';
import EditIcon from '@mui/icons-material/Edit';
import {
    useParams,
    useNavigate
} from "react-router-dom";
import AddCoapplicant from './AddCoapplicant';
import { Card } from '@mui/material';
import AtsDashboard from '../../AtsDashboard';
import AddressRentEdit from './AddressRentEdit';
import NewApplicationModel from './NewApplicationModel';
import WarningPrelimNotice from './WarningPrelimNotice';
import FilterModelAdmin from './FilterModelAdmin';
// import FilterModelAdminSubriber from './FilterModelAdminSubriber';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import FlagIcon from '@mui/icons-material/Flag';
import Zoom from '@mui/material/Zoom';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
// import { setSwitchSubscriber } from '../../Store/Slice/usersSlice';
import { useDispatch } from 'react-redux';

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#909090',
        boxShadow: theme.shadows[1],
        fontSize: 12,
        //   fontFamily: 'Open Sans';
        fontFamily: 'inherit',
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
        maxWidth: 150,
    },
    [`& .${tooltipClasses.arrow}`]: {
        "&:before": {
            border: "1px solid #E6E8ED"
        },
        color: theme.palette.common.white
    },
}));

export const ToogleFilterInboxType = (props) => {

    const { setFilters, filterValues, displayedFilters } = useListContext();
    useEffect(() => {
        if (!filterValues.app_invitation_type) {
            setFilters({ 'app_invitation_type': "1" }, ['app_invitation_type'])
        }
    }, [])

    return (
        <>
            {/* <JSONTree data={filterValues}/> */}
            <ToggleButtonGroup
                sx={{ paddingBottom: 0.5, '& .Mui-selected': { fontWeight: 700 } }}
                size="small"
                value={filterValues.app_invitation_type}
                color="primary"
                exclusive
                onChange={(event: React.MouseEvent<HTMLElement>,
                    newAlignment: string) => {
                    if (newAlignment) {
                        setFilters({ 'app_invitation_type': newAlignment }, ['app_invitation_type'])
                    }
                }}
                aria-label="Inbox"
            >
                <ToggleButton value={"1"} aria-label="Application">
                    Application
                </ToggleButton>
                <ToggleButton value={"2"} aria-label="Docupload">
                    Docupload
                </ToggleButton>
                <ToggleButton value={"3"} aria-label="Docupload">
                    Guest Card
                </ToggleButton>
            </ToggleButtonGroup>
        </>)
};

export const FilterListInbox = (props) => {
    return <>
        {/* <Button size='large' variant='outlined' startIcon={} label='' onClick={() => { }} /> */}
        <IconButton sx={{
            border: '1px solid',
            borderRadius: ' 5px',
            padding: '6px',
            marginLeft: '-10px',
            marginBottom: '4.4px'
        }} size='medium' aria-label="delete">
            <FilterListIcon />
        </IconButton>
    </>
}

export const filters = [
    <ToogleFilterInboxType source="app_invitation_type" alwaysOn />,
    <SearchInput variant="outlined" sx={{ backgroundColor: '#fff' }} placeholder="Search" InputLabelProps={{
        shrink: true,
    }} source="q" alwaysOn />,
];

export default function BasicPopover() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton color='warning' style={{ color: '#F9BB04' }} size='small' aria-label="info" aria-describedby={id} onClick={handleClick}>
                <InfoIcon style={{ fontSize: '14' }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>This is the Rent to Income Ratio.</Typography>
            </Popover>
        </div>
    );
}

const DatagridTableHeaderCell = ({ child }) => {
    const {
        sort,
        setSort
    } = useListContext();
    const [sortStatus, SetSortStatus] = React.useState(false);
    const sortBy = child.props.sortBy || child.props.source;
    return (<>
        {/* !sort.order?'ASC':sort.order=='ASC'?'DESC':'ASC' */}
        <Box display={'flex'} alignItems={'center'} justifyContent={'start'}>
            <span style={{ padding: '0px 2px' }}>
                {sortBy === sort.field ? sort.order == "ASC" ? <ArrowUpwardIcon style={{ fontSize: 18 }} /> : <ArrowDownwardIcon style={{ fontSize: 18 }} /> : null}
            </span>
            <span style={{ cursor: 'pointer', fontWeight: 'bold', color: sortBy === sort.field ? '#000' : '#909090' }} onClick={() => { sortBy && setSort({ 'field': sortBy, 'order': 'ASC' }) }}>{child.props.label || child.props.source}</span>
            {/* <Button size='small'  label={child.props.label || child.props.source} endIcon={} /> */}
            {(child.props.label || child.props.source) == "RI%" && <BasicPopover />}
        </Box>

    </>);
}

const DatagridHeader = (props) => {
    const { children, isRowSelectable, isRowExpandable } = props;
    return (
        <TableHead sx={{ height: 50, backgroundColor: '#FFFFFF', border: '0.5px solid #E0E0E0' }}>
            <TableRow>
                {isRowSelectable && <TableCell></TableCell>}{/* empty cell to account for the select row checkbox in the body */}
                {React.Children.map(children, child => {
                    if (child)
                        return (
                            <TableCell sortDirection="asc" key={child.props.source}>
                                <DatagridTableHeaderCell child={child} />
                            </TableCell>
                        )
                })}
                {/* {isRowExpandable && <TableCell></TableCell>} */}
            </TableRow>
        </TableHead>
    )
};

const MyDatagridRow = (props) => {
    const { record, id, onToggleItem, children, selected, selectable, isRowExpandable, isRowSelectable, expand } = props;
    const [open, setOpen] = useStore('expandedTable', 0);
    const [tempOpen, setTempOpen] = React.useState(false);
    return (
        <RecordContextProvider value={record}>
            <TableRow className={props.className}>
                {/* first column: selection checkbox */}
                {isRowSelectable && <TableCell padding="none">
                    <Checkbox
                        disabled={!selectable}
                        checked={selected}
                        onClick={event => onToggleItem(id, event)}
                    />
                </TableCell>}
                {/* data columns based on children */}
                {React.Children.map(children, field => {
                    if (field)
                        return (
                            <TableCell key={`${id}-${field.props.source}`}>
                                {field}
                            </TableCell>
                        )
                })}
                {/* {isRowExpandable && <TableCell padding="none" >
                    <IconButton
                        aria-label="expand row"
                        size="small"

                        onClick={() => {
                            setTempOpen(!tempOpen)
                            setOpen(open == id ? open != 0 ? 0 : id : id)
                        }}
                    >
                        {open == id ? <KeyboardArrowUpIcon sx={{ color: open == id ? "primary.main" : '#fff' }} /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>} */}
            </TableRow>
            {/* {isRowExpandable && <TableRow>
                <TableCell padding='none' colSpan={12}>
                    <Collapse in={open == id} timeout="auto" unmountOnExit>
                        {expand}
                    </Collapse>
                </TableCell>
            </TableRow>} */}
        </RecordContextProvider>
    )
};

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow {...props} />} />;
const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody {...props} />} />;
export const circularBtn = {
    width: 40,
    height: 40,
    borderRadius: 50,
    margin: '0px 40px',
}

// const TopMenu = (props) => {
//     return (<>
//         <div className="app-head-row mr-t30 mb-2">
//             <FilterModelAdminSubriber />
//         </div>
//     </>)
// }

const MyList = (props) => {
    const navigate = useNavigate();
    const { children, actions, filters, title, ...rest } = props;
    return (
        <ListBase perPage={100} {...props}>
            {/* <Header /> */}
            {/* <TopMenu /> */}
            <br />
            <div role="presentation">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" onClick={() => navigate(-1)}>
                        Dashboard
                    </Link>
                    <Typography color="text.primary">Subscriber Usage List</Typography>
                </Breadcrumbs>
            </div>
            <AtsDashboard isAdmin={true} />
            <Card>
                {children}
            </Card>
            <Pagination rowsPerPageOptions={[100,200,300,400,500]}/>
        </ListBase>
    )
};

export const GetAdminSubcriberList = (props) => {
    // const { id } = useParams();
    // filter={{ 'subscriberid': id }}
    return (
        <MyList sort={{ field: 'LastMonthApps', order: 'DESC' }} actions={null} filters={false} exporter={false} title={'Inbox'}>
            <DatagridTable />
        </MyList>
    )
};

const DatagridTable = (props) => {
    const [switchSubscriberObj, setSwitchSubscriberObj] = useStore('switchSubscriberName',{value:'',label:''});
    // const [switchSubscriber, setSwitchSubscriber] = useStore('switchSubscriber');
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter
    } = useListContext();
    const navigate = useNavigate();
    return (<MyDatagrid isRowExpandable={true} isRowSelectable={false}
        header={<DatagridHeader isRowExpandable={true} />}
        sx={{
            '& .RaDatagrid-rowEven': { backgroundColor: "#F9F9F9" },
            '& .RaDatagrid-expandIcon': { color: '#fff' },
            '& .RaDatagrid-expandIconCell': { backgroundColor: "primary.main" },
            '& .RaDatagrid-headerCell': {
                fontWeight: 'bold',
                fontSize: 12
            },
            '& .RaDatagrid-table': {
                fontSize: 12
            },
            '& .MuiToggleButton-root.Mui-selected': {
                fontWeight: 700
            },
        }}>
        <TextField source="CompanyName" label="Company Name" />
        <TextField source="CompanyType" label="Company Type" />
        <DateField source="LastApplicationDate" label="Last App" locales="en-US" />
        <DateField source="LicenseExpiration" label="LiCense Expiration" locales="en-US" />
        <NumberField source="LastMonthApps" label="Last 30 Days App" />
        <NumberField source="PreviousThrityDaysApp" label="Previous 30 Days App" />
        <FunctionField
          render={(record) => {
            // let flagOnValue = record?.LastMonthApps && record?.LastMonthApps > 0 && record?.PreviousThrityDaysApp && record?.PreviousThrityDaysApp > 0 ? (record?.PreviousThrityDaysApp / record?.LastMonthApps) * 100 : 0;
            let flagOnValue =
              record?.LastMonthApps &&
              record?.LastMonthApps > 0 &&
              record?.PreviousThrityDaysApp &&
              record?.PreviousThrityDaysApp > 0
                ? ((record?.LastMonthApps - record?.PreviousThrityDaysApp) /
                    record?.PreviousThrityDaysApp) *
                  100
                : 0;
            let ChangeOfValues =
              record?.LastMonthApps - record?.PreviousThrityDaysApp;
            return (
              <>
                <LightTooltip
                  TransitionComponent={Zoom}
                  title={
                    flagOnValue === 0 && ChangeOfValues < 0
                      ? "App Count dropped to 0 in last 30 days"
                      : flagOnValue >= 1 && flagOnValue <= 50
                      ? `App Count increased by ${flagOnValue?.toFixed(
                          2
                        )}% in last 30 days`
                      : flagOnValue <= -0.1 && flagOnValue >= -50
                      ? `App Count dropped by ${-flagOnValue.toFixed(
                          2
                        )}% in last 30 days`
                      : flagOnValue <= -50
                      ? `App Count dropped by ${
                          flagOnValue >= -100 ? -flagOnValue?.toFixed(2) : `100`
                        }% in last 30 days`
                      : flagOnValue === 0 && ChangeOfValues > 0
                      ? ""
                      : flagOnValue === 0 && ChangeOfValues === 0
                      ? ""
                      : ""
                  }
                  arrow
                >
                  <button className="border-0 bg-transparent">
                    <Button
                      startIcon={
                        <FlagIcon
                          style={{
                            color:
                              flagOnValue === 0 && ChangeOfValues < 0
                                ? "#FF4848"
                                : flagOnValue >= 1 && flagOnValue <= 50
                                ? "#F9BB04"
                                : flagOnValue <= -0.1 && flagOnValue >= -50
                                ? "#FF4848"
                                : flagOnValue <= -50
                                ? "#FF4848"
                                : flagOnValue === 0 && ChangeOfValues > 0
                                ? "#01DB3E"
                                : flagOnValue === 0 && ChangeOfValues === 0
                                ? "#F9BB04"
                                : "#01DB3E",
                          }}
                          color="success"
                        />
                      }
                      label=""
                    />
                  </button>
                </LightTooltip>
              </>
            );
          }}
        />
        <FunctionField render={record => {
            return (<>
                <Button startIcon={<AutorenewIcon />} label='' onClick={() => {
                    setSwitchSubscriberObj({value:record.id,label:record.CompanyName});
                    // setSwitchSubscriber("true");
                    localStorage.setItem("RaStore.switchSubscriber", JSON.stringify(true))
                    navigate(`/ats/subscriber/inbox/${record?.id}`, { replace: true })
                }} />
            </>);
        }} />
        {/* <Button  startIcon={<FlagIcon/>} label='' /> */}
        {/* <Button startIcon={<AutorenewIcon/>} label='' /> */}
    </MyDatagrid>)
};

/**
 * this table is display under expended
 * @param props
 * @returns
 */
// const ExpandTableComponent = (props) => {
//     const record = useRecordContext();
//     const { id } = useParams();
//     const {
//         filterValues,
//     } = useListContext();
//     return (<Paper elevation={0} >
//         <Box sx={{ width: '100%', p: 1 }} >
//             <GetsubscriberinboxListDetails isAtsApp={(filterValues?.app_invitation_type != 2 && filterValues?.app_invitation_type != 3)} id={record.id} secondaryApp={record.SecondaryApp} />
//             <Box sx={{ p: 1, background: 'rgba(44, 192, 228, 0.08)' }} display={'flex'} justifyContent={'space-between'} >
//                 <Box sx={{ py: 1 }} display={'flex'}>
//                     {(filterValues?.app_invitation_type != 2 && filterValues?.app_invitation_type != 3) && <AddCoapplicant record={record} />}
//                     <Archive record={record} />
//                     {record.Subscribertype == 'Real Estate' && <TransferApp record={record} />}
//                     {/* hide a  Share App/Report for now */}
//                     {/* {(filterValues?.app_invitation_type != 2 && filterValues?.app_invitation_type != 3) && <MuiButton startIcon={<NextPlanOutlinedIcon />} variant='text'>Share App/Report</MuiButton>} */}
//                 </Box>
//                 {/* {record?.ApplicationStatus !== "Invited"&&<Link style={{ textDecoration: 'none' }} to={`/Ats/Subscriber/Inbox/${id}/edit/${record.id}/${filterValues?.app_invitation_type}`} state={{ ...record }} >
//                     <button type="button" className="blue-btn px-4">
//                         View Details
//                     </button>
//                 </Link>} */}
//             </Box>
//         </Box>
//     </Paper>)
// }