import { useState } from "react";
import { Resource } from "react-admin";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { CgNotes } from "react-icons/cg";
import AuditLogsList from "../../Ats/AuditLogs/AuditLogsList";

const AuditlogModal = ({ record }) => {
  const [openAuditLogs, setOpenAuditLogs] = useState(false);
  const openAuditLogsHandler = () => setOpenAuditLogs(true);
  return (
    <>
      <div className="basic-subinfo px-2">
        <div className="modal-sm-button">
          <button
            type="button"
            className="p-0 mt-0"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={openAuditLogsHandler}
            style={{ lineHeight: "22px" }}
          >
            <CgNotes style={{ fontSize: "14px" }} /> <br />
            <span>Logs</span>
          </button>
        </div>
      </div>
      <Dialog
        fullWidth={true}
        maxWidth={false}
        open={openAuditLogs}
        onClose={() => setOpenAuditLogs(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root .MuiDialogContent-root": { paddingTop: "0px" },
        }}
      >
        <DialogContent>
          <Resource
            name="application-logs"
            list={<AuditLogsList record={record} />}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AuditlogModal;
