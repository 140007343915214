import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/DocuVerusLogoorg.png";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormProvider, useForm } from "react-hook-form";
// import { toast, ToastContainer } from "react-toastify";
import { Outlet, Link, useNavigate, Navigate } from "react-router-dom";
import InputField2 from "../../componets/common/InputComponents/InputField2";
import { Button, useCreate, useNotify, useRefresh } from "react-admin";
import { CircularProgress } from "@mui/material";
import { useLogin, Notification } from "react-admin";
import { SOFTWARE_VERSION } from "../../config";
import InputCheckBoxField from "../../componets/common/InputComponents/InputCheckBoxField";

const ForgotPassword = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(" ");
  const [create, { isLoading, error }] = useCreate();

  const schema = yup.object().shape({
    email: yup
      .string()
      .typeError("Email or Username is required!")
      .matches(/^$|^\S+.*/g, "Enter a valid value")
      .required("Email or Username is required!")
      .email("Email is required")
      .matches(/^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/, "Invalid domain"),
  });

  // Initial form values
  const defaultFormValues = {
    email: "",
    select_for_username:false
  };

  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
 console.log(methods.watch('select_for_username'),"select_for_username");
  useEffect(() => {
    document.title = "Docuverus";
  }, []);

  const onSubmit = (data) => {
    const sendData = {
      username: data?.email,
    };
   create(
      "forgotPassword",
      { data: sendData },
      {
        onSuccess: (data) => {
          notify(`${data?.message}`, {
            type: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
          });
          setErrorMessage(""); 
          methods?.reset();
        },
        onError: (e: any) => {
          // notify(`Operation fail: ${e.message}`, {
          //   type: "error",
          //   anchorOrigin: { vertical: "top", horizontal: "right" },
          // });
          setErrorMessage(`${e.message}`);
        },
      }
    );
  };
   return (
    <section className="login-wrap">
      <div className="login-cell">
        <div className="login-block">
          <div className="l-logo-block text-center">
            <img src={Logo} alt="Docuverus" title="Docuverus" />
          </div><br/>
          {errorMessage && <p style={{color:"red",textAlign:'center',fontSize:'12px',fontWeight:'bold'}} >{errorMessage}</p>}
        
         <div className="auth-form mr-t30">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className="form-group cus-input-group mr-b20">
                  <InputField2
                    label={methods.watch('select_for_username')?"Username":"Email"}
                    source={`email`}
                    placeholder={methods.watch('select_for_username')?"Enter Username Here":"Enter Email Here"}
                    labelClass={"lable-title"}
                    additionalInputClass={"cus-control"}
                  />
                </div>
                <div className="form-group cus-input-group mr-b20">
                  <InputCheckBoxField
                    notRequired={true}
                    label={"Select for UserName"}
                    name={`select_for_username`}
                  />
                </div>
                {isLoading ? (
                  <div className="static-d-info d-flex justify-content-center align-items-center mw-100">
                    <CircularProgress size="20px" />
                    <h5>&nbsp; &nbsp; Please wait...</h5>
                  </div>
                ) : (
                  <div className="d-flex justify-content-center align-items-center">
                    <button type="submit" className="l-btn-forgetPsd">
                      Reset Password
                    </button>
                  </div>
                )}
              </form>
              <br/>
              <div className="w-full p-t-3 p-b-5 d-flex justify-content-end">
                <Link to="/" className="txt1" style={{ color: "5555FF" }}>
                  Back to Login
                </Link>
              </div>
            </FormProvider>
          </div>
          <div className="l-foot-links mr-t20" >
            <ul className="mx-auto">
              <li></li>
              <li></li>
              <li >Version {SOFTWARE_VERSION}</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
