import {
  Datagrid,
  FunctionField,
  ListBase,
  Pagination,
  TextField,
  useListContext,
  useStore,
} from "react-admin";
import { Grid, Typography, TextField as MuiTextField } from "@mui/material";
import { debounce } from "lodash";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import DateComponent from "../DateComponent";

// Define a filter component for the list view
const MyListToolBar = ({ title }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
    useListContext();

  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here
    setFilters({ ...filterValues, q_Logs: value }, []);
  }, 500); // 500ms debounce delay

  const handleSearchChange = (event) => {
    const value = event.target.value;

    debouncedSearch(value);
  };

  return (
    <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
      {/* Left side with title */}
      <Grid item xs={12} md={6}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      {/* Right side with search input, filter button, and add button */}
      <Grid
        item
        xs={12}
        md={6}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <div className="group-btn">
          {/* Search Input */}
          <MuiTextField
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            defaultValue={filterValues.q_Logs}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
          />

          {/* Filter Button */}
        </div>
      </Grid>
    </Grid>
  );
};

// Define the list view component using ListBase
export const MyList = ({ children, toolBar, ...props }) => {
  return (
    <ListBase
      {...props}
      perPage={100} // Number of items per page
    >
      {toolBar}

      {children}

      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
    </ListBase>
  );
};

const AuditLogsList = ({ record }) => {
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  return (
    <MyList
      toolBar={<MyListToolBar title={"Audit Logs"} />}
      sort={{ field: "id", order: "DESC" }}
      filter={{ applicationNumber: record?.AppID, applicantID: record?.id }}
      disableSyncWithLocation
    >
      <Datagrid
        sx={{
          "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
          },
          "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        }}
        bulkActionButtons={false}
      >
        <FunctionField
          label="Date"
          render={(record) => <DateComponent date={record?.CreatedDate} />}
          sortBy="CreatedDate"
        />
        <TextField source="Action" />
        <TextField source="ActionArea" />
        <TextField source="UserName" />
        <TextField label="Browser" source="Browser_Version" />
        <TextField source="OS" />
        <TextField label="IP Address" source="IP_Location" />
        {/* Add more fields as needed */}
      </Datagrid>
    </MyList>
  );
};

export default AuditLogsList;
