import {
  Datagrid,
  FunctionField,
  LinearProgress,
  ListBase,
  ListToolbar,
  Pagination,
  TextField,
  Title,
  useCreate,
  useDataProvider,
  useGetIdentity,
  useListContext,
  useStore,
} from "react-admin";
import { useParams, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { DatagridHeader, MyDatagrid } from "../../Ats/GetsubscriberinboxList";
import { useSelector } from "react-redux";
import { Card } from "@mui/material";
import { NumberField, Filter, TextInput } from "react-admin";
import {
  Grid,
  Typography,
  TextField as MuiTextField,
  Button,
  Box,
  Link
} from "@mui/material";
import { debounce } from "lodash";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ButtonBase from '@mui/material/ButtonBase';
import FilterListIcon from "@mui/icons-material/FilterList";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CopyLinkHoverDropdown from "./CopyLinkHoverDropdown";
import FilterModal from "./FilterModal";
import { ATS_API_URL } from "../../../config";
import InputFileImport from "../../common/InputFileImport";
import DownloadSampleButton from "../../common/DownloadSampleButton";

// Define a filter component for the list view
const MyListToolBar = ({ title, ...props }) => {
  useEffect(()=>{
    setFilters({},[])
   },[])
  const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
    useListContext();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");

  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here
    setFilters({ ...filterValues, q: value }, []);
  }, 500); // 500ms debounce delay

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    debouncedSearch(value);
  };
 
  return (
    <Grid style={{ marginTop: 10, marginBottom: 14 }} container spacing={3}>
      {/* Left side with title */}
      <Grid item xs={12} md={2}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      {/* Right side with search input, filter button, and add button */}
      <Grid
        item
        xs={12}
        md={10}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <div className="group-btn">
          {/* Search Input */}
          <MuiTextField
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
          />

          {/* Filter Button */}
          <FilterModal />
          {/* file export Button */}
          <button
            onClick={() =>
              window.open(
                `${ATS_API_URL}/subscriber/download-xls?downloadType=${
                  props?.type === "Office" ? "Office" : "Property"
                }&subscriberID=${switchSubscriberObj?.value}&_sort=${
                  sort?.field || ""
                }&_order=${sort?.order || ""}&_search=${
                  filterValues?.q || ""
                }&_filter=${
                  filterValues?.propertyStatus
                    ? filterValues?.propertyStatus?.toString()
                    : ""
                }`
              )
            }
            type="button"
            className="g-line-btn"
            title="Export all data"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_14375_6193)">
                <path
                  d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                  fill="#2DC3E8"
                />
              </g>
              <defs>
                <clipPath id="clip0_14375_6193">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
          {/* Download Sample Button*/}
          <DownloadSampleButton
            downloadType={props?.type === "Office" ? "Office" : "Property"}
          />
          {/* Download Sample Button*/}
          {/* Import CSV Button*/}
          <InputFileImport
            buttonText={"Import CSV"}
            importType={props?.type === "Office" ? "Office" : "Property"}
          />
          {/* Import CSV Button*/}
          {/* Add property button */}
          <button
            type="button"
            onClick={() =>
              navigate(
                `/ats/settings/${
                  props?.type === "Office"
                    ? "officeSetup/addNewOffice"
                    : "propertySetup/addNewProperty"
                }`,
                {
                  state: { type: props?.type },
                }
              )
            }
            className="blue-btn px-3"
          >
            <svg
              className="mr-r10"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                fill="white"
              />
            </svg>
            {props?.type === "Office" ? "Office" : "Property"}
          </button>

          {/* Add Button */}
          {/* <Button variant="contained" color="primary" style={{ marginLeft: 10 }}>
                    Add
                </Button> */}
        </div>
      </Grid>
    </Grid>
  );
};

const postFilters = [
  <TextInput label="Search" source="q" alwaysOn />,
  <TextInput label="Title" source="title" defaultValue="Hello, World!" />,
];

// Define the list view component using ListBase
export const MyList = ({ children, toolBar, ...props }) => {
  return (
    <ListBase
      {...props}
      // filters={<MyFilter/>}
      // filters={<MyFilter />}
      perPage={100} // Number of items per page
      // pagination={<Pagination />}
    >
      {toolBar}
      {/* Define the structure of each list item using Datagrid */}
      {/* <Card> */}
      {children}
      {/* </Card> */}
      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
    </ListBase>
  );
};

export const ActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#E6FDF0",
  };
  const myTextStyles = {
    color: "var(--Success, #46A96F)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Active</div>
    </div>
  );
};
export const DeActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#FFF1F1",
  };
  const myTextStyles = {
    color: "var(--Success, #FF4848)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Inactive</div>
    </div>
  );
};

export const StripeSetupBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
  };
  const myTextStyles = {
    color: "var(--Primary-CTA, #2DC3E8)",
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
  };

  return (
    <div style={myStyles}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <g clipPath="url(#clip0_4710_10800)">
          <path
            d="M6 1.33398V2.66732H3.33333L3.33267 9.33398H12.666L12.6667 2.66732H10V1.33398H13.3333C13.5101 1.33398 13.6797 1.40422 13.8047 1.52925C13.9298 1.65427 14 1.82384 14 2.00065V14.0007C14 14.1775 13.9298 14.347 13.8047 14.4721C13.6797 14.5971 13.5101 14.6673 13.3333 14.6673H2.66667C2.48986 14.6673 2.32029 14.5971 2.19526 14.4721C2.07024 14.347 2 14.1775 2 14.0007V2.00065C2 1.82384 2.07024 1.65427 2.19526 1.52925C2.32029 1.40422 2.48986 1.33398 2.66667 1.33398H6ZM12.666 10.6673H3.33267L3.33333 13.334H12.6667L12.666 10.6673ZM11.3333 11.334V12.6673H10V11.334H11.3333ZM8.66667 1.33398V4.66732H10.6667L8 7.33398L5.33333 4.66732H7.33333V1.33398H8.66667Z"
            fill="#2DC3E8"
          />
        </g>
        <defs>
          <clipPath id="clip0_4710_10800">
            <rect width="16" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <p style={myTextStyles}>Setup</p>
    </div>
  );
};
export const DatagridTable = (props) => {
  const { id } = useParams();
  const { displayedFilters, filterValues, setFilters, hideFilter } =
    useListContext();
  const { data, isLoading, isFetching } = useListContext();
  useEffect(() => {
    if (isFetching || isLoading) window.document.title = "Loading";
    else {
      window.document.title = "Docuverus Dashboard";
    }
  }, [isFetching, isLoading]);
  // get user role from  global store
  // const { data: identity } = useGetIdentity();
  // const userRole = useSelector((state: any) => state?.users?.role) || identity?.userrole;
  // const location = useLocation();

  // const [create] = useCreate();
  // const dataProvider = useDataProvider();

  // const onClickHandler = async (event, record) => {
  //     // First API call using react-admin's dataProvider
  //     // const applicantData = await dataProvider.getList(
  //     //     "get_subscriber_inbox_detials",
  //     //     {
  //     //         filter: {
  //     //             ApplicationNumber: record?.id,
  //     //             subscriberid: record?.SubscriberID,
  //     //         },
  //     //         pagination: { page: 0, perPage: 0 },
  //     //         sort: { field: "", order: "DESC" },
  //     //     }
  //     // );
  // };
  return (
    <>
      {(isFetching || isLoading) && <LinearProgress />}
      <Datagrid
        sx={{
          "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
          "& .RaDatagrid-expandIcon": { color: "#fff" },
          "& .RaDatagrid-expandIconCell": { backgroundColor: "primary.main" },
          "& .RaDatagrid-headerCell": {
            fontWeight: "600 !important",
            fontSize: 12,
            color: "#303030 !important",
          },
          "& .RaDatagrid-table": {
            fontSize: 12,
          },
          "& .MuiToggleButton-root.Mui-selected": {
            fontWeight: 700,
          },
        }}
      >
        {/* <TextField source="ROWNUM" label="Sr. No" /> */}
        {/* <FunctionField source='Id' label="App Id" sortByOrder="DESC" sortBy='id' render={record => {
                    return <>
                        <Box display={'flex'} justifyContent={'start'} flexDirection={'column'}>
                            {userRole == 'Docuverus Super Admin' && !record.Archived && record?.ApplicationStatus !== "Cancelled" && !filterValues.archiveCancelApp ?
                                <Link style={{ textDecoration: 'none' }} to={`/Ats/Subscriber/Inbox/${record.SubscriberID || id || identity?.subscriberid}/edit/${record.id}/${record?.ApplicationType == "Doc Upload" ? 2 : record?.ApplicationType == "Application" ? 1 : filterValues?.app_invitation_type}${record ? `?data=${encodeURIComponent(btoa(JSON.stringify({ ...record, from: location })))}` : ''}`} state={{ ...record }} onClick={(event) => onClickHandler(event, record)}>
                                    <Typography fontWeight={700} color='primary'>{record.id}</Typography>
                                </Link>
                                :
                                (filterValues?.app_invitation_type == 1 && record?.ApplicationStatus !== "Cancelled" && !record.Archived && record?.ApplicationStatus !== "Invited") ||
                                    (filterValues?.app_invitation_type != 1 && record?.ApplicationStatus !== "Cancelled" && !record.Archived && record?.ApplicationStatus !== "Invited" && record?.ApplicationStatus !== "Review Pending") && !filterValues.archiveCancelApp ?
                                    <Link style={{ textDecoration: 'none' }} to={`/Ats/Subscriber/Inbox/${record.SubscriberID || id || identity?.subscriberid}/edit/${record.id}/${record?.ApplicationType == "Doc Upload" ? 2 : record?.ApplicationType == "Application" ? 1 : filterValues?.app_invitation_type}${record ? `?data=${encodeURIComponent(btoa(JSON.stringify({ ...record, from: location })))}` : ''}`} state={{ ...record }} onClick={(event) => onClickHandler(event, record)}>
                                        <Typography fontWeight={700} color='primary'>{record.id}</Typography>
                                    </Link>
                                    :
                                    <Typography fontWeight={700} color='#303030'>{record.id}</Typography>
                            }
                        </Box>
                    </>;
                }} /> */}
        {/* <TextField source="id" /> */}
        <TextField label={"Property Name"} source="PropertyName" />
        <TextField source="Email" />
        <TextField source="Telephone" />
        <TextField source="ProfileName" label={"Application Profile"} />
        <FunctionField
          label={"Stripe Account"}
          render={(record) =>
            record.StripeAccountEnabled ? <ActiveBtn /> : <StripeSetupBtn />
          }
        />
        <TextField source="ApplicationFee" label={"App fee"} />
        <FunctionField
          label={"Status"}
          render={(record) => (record.Active ? <ActiveBtn /> : <DeActiveBtn />)}
        />
      </Datagrid>
    </>
  );
};
/**
 * 
 * <div className="app-head-row mr-t30 mb-2">
            <div className="a-top-right-block">
                <div className="cus-search">
                    <input ref={ref} type="text" onChange={(e) => {
                        let timeout;
                        if (timeout) clearTimeout(timeout);
                        timeout = setTimeout(() => {
                            // navigate('/Ats/Admin/getglobalsearch', { state:{search: e.target.value} })
                        }, 2000)
                    }} placeholder="Global Search" />
                </div>
                <div className="group-btn">
                    {/* <NewApplicationModel isSuperUser={true} /> 
                    </div>
                    </div>
                    </div>
 */

// export const GenralList = (props) => {
//     // // const { children, title, ...rest } = props;
//     const ref = React.useRef<null | HTMLInputElement>(null);
//     return (
//         <ListBase  {...props}>
//             {/* <Header /> */}
//             <br />

//             <Card>
//                 <DatagridTable />
//             </Card>
//             <Pagination />
//         </ListBase>
//     )
// };
// const PropertyList = () => {
//     return MyList
//     return <GenralList toreKey="Property" sort={{ field: 'id', order: 'DESC' }} actions={null} filter={{ subscriberID: 1 }} exporter={false} title={'Property List'}/>
// }

const PropertyList = (props) => {
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  return (
    <MyList
      toolBar={
        <MyListToolBar
          title={props?.type === "Office" ? "Office List" : "Property List"}
          type={props?.type}
        />
      }
      sort={{ field: "id", order: "DESC" }}
      filter={{ subscriberID: switchSubscriberObj?.value }}
    >
      <Datagrid
        sx={{
          "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
          },
          "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
        }}
        bulkActionButtons={false}
      >
        {/* <TextField source="id" /> */}
        <FunctionField
          sortByOrder="ASC"
          sortBy={props?.type === "Office" ?"OfficeName":"PropertyName"}
          label={props?.type === "Office" ?"Office Name":"Property Name"}
          render={(record) => (
            // <Box display={"flex"}>
            <Link  onClick={() =>
              navigate(
                `/ats/settings/${
                  props?.type === "Office"
                    ? "officeSetup/officeDetails/"
                    : "propertySetup/propertyDetails/"
                }${record?.id}`,
                { state: { type: props?.type } }
              )
            } underline="none" style={{cursor:"pointer"}} rel="noopener noreferrer" >{props?.type === "Office" ? record.OfficeName:record.PropertyName} </Link>
          )}
        />
        {/* {props?.type === "Office" ? (
          <TextField source="OfficeName"/>
        ) : (
          <TextField source="PropertyName" />
        )} */}
        <TextField source="Email" />
        <TextField source="Telephone" />
        <TextField source="ProfileName" label={"App Profile"} />
        {/* <FunctionField
          label={"Stripe"}
          render={(record) =>
            record.StripeAccountEnabled ? <ActiveBtn /> : <StripeSetupBtn />
          }
        /> */}
        {/* <TextField source="ApplicationFee" label={"App fee1"} /> */}
        <FunctionField
          sortByOrder="DESC"
          sortBy="Status"
          label={"Status"}
          render={(record) => (record.Active ? <ActiveBtn /> : <DeActiveBtn />)}
        />
        <FunctionField
          label={""}
          render={(record) => (
            // <Box display={"flex"}>
            <Box display={"flex"} sx={{ width: "100px" }}>
              <CopyLinkHoverDropdown record={{...record, linkType: "unCompleted", applicationUrl: true}} />
              <IconButton
                aria-label="view details"
                color="primary"
                sx={{
                  py: 0,
                }}
                onClick={() =>
                  navigate(
                    `/ats/settings/${
                      props?.type === "Office"
                        ? "officeSetup/officeDetails/"
                        : "propertySetup/propertyDetails/"
                    }${record?.id}`,
                    { state: { type: props?.type } }
                  )
                }
                className="show_on_hover"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          )}
        />
        {/* Add more fields as needed */}
      </Datagrid>
    </MyList>
  );
};

export default PropertyList;
