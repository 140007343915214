import React, { useEffect, useRef, useState } from "react";
import { useController } from "react-hook-form";

import { useFormContext } from "react-hook-form";
import DragDropDocumentButton from "../../DocumentAnalysis/components/DragDropDocumentButton";
import FormInstruction from "../FormInstruction";
import QRCode from "react-qr-code";
import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { RootState } from "../../../Store/store";
import { useSelector } from "react-redux";
import CustomPreviewUpload from "../CustomPreviewUpload";
import FilePreview from "../FilePreview";
import { JSONTree } from "react-json-tree";
import { REACT_APPLICANT } from "../../../config";

const RadioButtonGroupInputIds = ({
  source,
  label,
  disable = false,
  frontFile = null,
  backFile = null,
  setSelectedDocumentId,
  frontFileError,
  backFileError,
  Trnid = null,
}) => {
  const { data } = JSON.parse(localStorage.getItem("auth") || "");

  const [filePreview, setFilePreview] = useState({
    proof_of_income: "",
    proof_of_income_filePath: "",
  });
  const { field, fieldState } = useController({ name: source });
  const { getValues } = useFormContext();
  const navigate = useNavigate();
  const inputRef = useRef<HTMLDivElement>(null);
  const queryClient = useQueryClient();
  const userRole = useSelector((state: RootState) => state.users.role);
  // done changes to handle co-applicant case
  const userTrnId = useSelector((state: RootState) => state.users.userTrnId);
  const trn_id = Trnid || userTrnId;
  React.useEffect(() => {
    if (frontFileError || backFileError) inputRef?.current?.scrollIntoView();
  }, [frontFileError, backFileError]);

  useEffect(() => {
    setSelectedDocumentId(field.value);
  }, [field.value]);

  // flex-column flex-md-row flex-lg-row flex-xl-row
  return (
    <div className="row">
      <div className="col-md-8 col-sm-12">
        <div className="d-flex flex-wrap justify-content-between mt-2">
          <div className="">
            <>
              <div
                className="radios-wrap"
                ref={inputRef}
                style={{
                  borderBottom: 0,
                }}
              >
                <div className="mr-b5 d-flex align-items-center">
                  <input
                    type="radio"
                    id="licence"
                    disabled={
                      (getValues("file") || getValues("backfile")) != null
                    }
                    {...field}
                    value={"2"}
                    checked={
                      field.value == "2" || field.value == "13" ? true : false
                    }
                  />
                  <label className="radio" htmlFor="licence">
                    Driver's license/ state ID
                  </label>
                  <div></div>
                </div>
              </div>

              <div
                className="radios-wrap  mr-t20"
                style={{
                  borderBottom: 0,
                }}
              >
                <div className="mr-b5 d-flex align-items-center">
                  <input
                    type="radio"
                    id="Passport"
                    disabled={
                      (getValues("file") || getValues("backfile")) != null
                    }
                    {...field}
                    value={"1"}
                    checked={field.value == "1" ? true : false}
                  />
                  <label className="radio" htmlFor="Passport">
                    US Passport
                  </label>
                </div>
              </div>

              <div
                className="radios-wrap  mr-t20"
                style={{
                  borderBottom: 0,
                }}
              >
                <div className="mr-b5 d-flex align-items-center">
                  <input
                    type="radio"
                    id="Permanant_Residence_ID"
                    disabled={
                      (getValues("file") || getValues("backfile")) != null
                    }
                    {...field}
                    value={"10"}
                    checked={field.value == "10" ? true : false}
                  />
                  <label className="radio" htmlFor="Permanant_Residence_ID">
                    Permanent Resident ID
                  </label>
                </div>
              </div>

              <div
                className="radios-wrap  mr-t20"
                style={{
                  borderBottom: 0,
                }}
              >
                <div className="mr-b5 d-flex align-items-center">
                  <input
                    type="radio"
                    id="Visa"
                    disabled={
                      (getValues("file") || getValues("backfile")) != null
                    }
                    {...field}
                    value={"11"}
                    checked={field.value == "11" ? true : false}
                  />
                  <label className="radio" htmlFor="Visa">
                    Visa
                  </label>
                </div>
              </div>
              <div
                className="invalid-feedback"
                style={{ display: "block", fontSize: "70%" }}
              >
                {fieldState.error !== undefined && fieldState.error.message}
              </div>
              <div className="">
                <div className="d-flex flex-column flex-md-row flex-lg-row flex-xl-row ">
                  {(((field.value == "2" || field.value == "13") &&
                    (!getValues("file_image") || !getValues("backfile"))) ||
                    ((field.value !== "2" || field.value !== "13") &&
                      !getValues("file_image"))) && (
                      <>
                        <div className="d-none d-sm-none d-md-block d-lg-block mx-3 print-hide">
                          <h6
                            className="print-hide"
                            style={{ padding: 2, fontSize: "10px" }}
                          >
                            Scan ID on Mobile{" "}
                          </h6>
                          <div className="print-hide">
                            <QRCode
                              value={`${REACT_APPLICANT}/scan/${trn_id}/?token=${data.tokendata}`}
                              size={150}
                            />
                          </div>
                          <button
                            onClick={() =>
                              queryClient.invalidateQueries("proofOfIdsData")
                            }
                            type="button"
                            className="btn m-1 btn-primary print-hide mb-2"
                            style={{
                              backgroundColor: "rgb(45, 195, 232)",
                              color: "white",
                              padding: "8px 15px 8px 15px",
                            }}
                          >
                            {" "}
                            Refresh
                          </button>
                        </div>

                        <div className="text-center mx-auto d-block d-sm-block d-md-none d-lg-none d-xs-block print-hide">
                          <a
                            href={`${REACT_APPLICANT}/scan/${trn_id}/?token=${data.tokendata}`}
                            target="_blank"
                            className="btn btn-primary print-hide mb-2 mt-3  text-center"
                            style={{
                              backgroundColor: "rgb(45, 195, 232)",
                              color: "white",
                              marginLeft: 18,
                              padding: "8px 15px 8px 15px",
                            }}
                          >
                            {" "}
                            Scan Now
                          </a>
                          {/* <div className="wrapper"> */}
                          {/* <div className="line"></div> */}
                          {/* <div className="wordwrapper"> */}
                          <div className="word text-center mx-auto ">or</div>
                          {/* </div> */}
                          {/* </div> */}
                        </div>
                      </>
                    )}

                  <div className="d-none d-sm-none d-md-block d-lg-block print-hide">
                    {(((field.value == "2" || field.value == "13") &&
                      (!getValues("file_image") || !getValues("backfile"))) ||
                      ((field.value !== "2" || field.value !== "13") &&
                        !getValues("file_image"))) && (
                        <div className="print-hide wrapper">
                          <div className="line"></div>
                          <div className="wordwrapper">
                            <div className="word">or</div>
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="px-2 mt-2">
                    <div className="d-flex ">
                      <div className="px-1">
                        {getValues("file_image") ? (
                          <div>
                            <small
                              className="mute d-block"
                              style={{ fontSize: "10px" }}
                            >
                              {"Front File"}
                            </small>
                            <FilePreview
                              isId={true}
                              doc_id={getValues("application_document_id")}
                              img={getValues("file_image")}
                              tag={"delete_document_id"}
                              invalidateQueries={"proofOfIdsData"}
                              file={getValues("file")}
                              handleClick={() =>
                                setFilePreview({
                                  ...filePreview,
                                  proof_of_income: getValues(
                                    "application_document_id"
                                  ),
                                  proof_of_income_filePath:
                                    getValues("file_image"),
                                })
                              }
                              handleOnChange={() => {
                                const isCurrentFile =
                                  filePreview.proof_of_income_filePath ==
                                    getValues("file_image")
                                    ? true
                                    : false;

                                if (isCurrentFile) {
                                  setFilePreview({
                                    proof_of_income: "",
                                    proof_of_income_filePath: "",

                                    // index: null,
                                  });
                                }
                              }}

                            />
                          </div>
                        ) : (
                          <>
                            <DragDropDocumentButton
                              fetchRecord={() => null}
                              documentId={field.value}
                              label={"Front File"}
                              SourceId={1}
                              trn_id={trn_id}
                              disabled={false}
                              invalidateQueries={"proofOfIdsData"}
                              tag={`fileupload_proof_of_id`}
                              fileError={false}
                              multiple={true}
                            />
                            {frontFileError && (
                              <div
                                className="invalid-feedback bg-danger text-white d-inline"
                                style={{ display: "block", fontSize: "70%" }}
                              >
                                {"Front file required"}
                              </div>
                            )}
                          </>
                        )}
                      </div>
                      {(field.value == "2" || field.value == "13") && (
                        <div className="px-1">
                          {getValues("backfile") ? (
                            <div>
                              <small
                                className="mute d-block"
                                style={{ fontSize: "10px" }}
                              >
                                {"Back File"}
                              </small>
                              <FilePreview
                                isId={true}
                                doc_id={getValues("BarcodeDocumentID")}
                                img={getValues("backfile_image")}
                                tag={"delete_document_id"}
                                invalidateQueries={"proofOfIdsData"}
                                file={getValues("backfile")}
                                handleClick={() =>
                                  setFilePreview({
                                    ...filePreview,
                                    proof_of_income:
                                      getValues("BarcodeDocumentID"),
                                    proof_of_income_filePath:
                                      getValues("backfile_image"),
                                  })
                                }
                                handleOnChange={() => {
                                  const isCurrentFile =
                                    filePreview.proof_of_income_filePath ==
                                      getValues("backfile_image")
                                      ? true
                                      : false;

                                  if (isCurrentFile) {
                                    setFilePreview({
                                      proof_of_income: "",
                                      proof_of_income_filePath: "",

                                      // index: null,
                                    });
                                  }
                                }}

                              />
                            </div>
                          ) : (
                            <>
                              <DragDropDocumentButton
                                fetchRecord={() => null}
                                documentId={13}
                                label={"Back File"}
                                SourceId={1}
                                trn_id={trn_id}
                                invalidateQueries={"proofOfIdsData"}
                                tag={`fileupload_proof_of_id`}
                                fileError={false}
                                disabled={!getValues("file")}
                                multiple={true}
                              />
                              {frontFileError && (
                                <div
                                  className="invalid-feedback bg-danger text-white d-inline"
                                  style={{ display: "block", fontSize: "70%" }}
                                >
                                  {"Back file required"}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    {field.value == "2" && !getValues("file") && (
                      <div className="d-flex justify-content-start mt-1">
                        <FormInstruction message={"Upload Front File first"} />
                      </div>
                    )}
                    {(field.value == "2" || field.value == "13") &&
                      getValues("file_image") &&
                      !getValues("backfile") && (
                        <div className="d-flex justify-content-start mt-1">
                          <FormInstruction
                            message={
                              "Please upload a clear image of the back/barcode of your ID or scan it using the QR code."
                            }
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      </div>
      {filePreview.proof_of_income !== "" && (
        <div className="col-md-4 col-sm-12">
          <CustomPreviewUpload
            filePath={filePreview.proof_of_income_filePath}
          />
        </div>
      )}
    </div>
  );
};

export default RadioButtonGroupInputIds;
