import React, { createContext, useEffect } from 'react';
import '../../App.css';
import {
    ListBase,
    Pagination, SearchInput, useListContext,
} from 'react-admin';
import { ToggleButton, ToggleButtonGroup, } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { Card } from '@mui/material';
import FilterModelAdmin from './FilterModelAdmin';
import { DatagridTable } from './GetsubscriberinboxList';
import AtsDashboard from '../../AtsDashboard';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { useForm, FormProvider } from 'react-hook-form';
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowBack } from '@mui/icons-material';
// function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
//     // event.preventDefault();
//     console.info('You clicked a breadcrumb.');
// }
export const ToogleFilterInboxType = (props) => {

    const { setFilters, filterValues, displayedFilters } = useListContext();
    useEffect(() => {
        if (!filterValues.app_invitation_type) {
            setFilters({ 'app_invitation_type': "1" }, ['app_invitation_type'])
        }
    }, [])

    return (
        <>
            {/* <JSONTree data={filterValues}/> */}
            <ToggleButtonGroup
                sx={{ paddingBottom: 0.5, '& .Mui-selected': { fontWeight: 700 } }}
                size="small"
                value={filterValues.app_invitation_type}
                color="primary"
                exclusive
                onChange={(event: React.MouseEvent<HTMLElement>,
                    newAlignment: string) => {
                    if (newAlignment) {
                        setFilters({ 'app_invitation_type': newAlignment }, ['app_invitation_type'])
                    }
                }}
                aria-label="Inbox"
            >
                <ToggleButton value={"1"} aria-label="Application">
                    Application
                </ToggleButton>
                <ToggleButton value={"2"} aria-label="Docupload">
                    Docupload
                </ToggleButton>
                <ToggleButton value={"3"} aria-label="Docupload">
                    Guest Card
                </ToggleButton>
            </ToggleButtonGroup>
        </>)
};

export const FilterListInbox = (props) => {
    return <>
        {/* <Button size='large' variant='outlined' startIcon={} label='' onClick={() => { }} /> */}
        <IconButton sx={{
            border: '1px solid',
            borderRadius: ' 5px',
            padding: '6px',
            marginLeft: '-10px',
            marginBottom: '4.4px'
        }} size='medium' aria-label="delete">
            <FilterListIcon />
        </IconButton>
    </>
}

export const filters = [
    <ToogleFilterInboxType source="app_invitation_type" alwaysOn />,
    <SearchInput variant="outlined" sx={{ backgroundColor: '#fff' }} placeholder="Search" InputLabelProps={{
        shrink: true,
    }} source="q" alwaysOn />,
];

export function BasicPopover() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton color='warning' style={{ color: '#F9BB04' }} size='small' aria-label="info" aria-describedby={id} onClick={handleClick}>
                <InfoIcon style={{ fontSize: '14' }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>This is the Rent to Income Ratio.</Typography>
            </Popover>
        </div>
    );
}

const TopMenu = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const {
        displayedFilters,
        filterValues,
        setFilters,
        hideFilter
    } = useListContext();
    const form = useForm({
        defaultValues: filterValues,
    });
    const applyFilter = (values) => {
        if (Object.keys(values).length > 0) {
            const filter = { ...values, end_date: values.end_date ? moment(values.end_date).format('MM/DD/yyyy') : '', start_date: values.start_date ? moment(values.start_date).format('MM/DD/yyyy') : '' };
            setFilters(filter, displayedFilters);
        };
    }

    const [q, setQ] = React.useState<null | string>(filterValues?.q);
    const ref = React.useRef<null | HTMLInputElement>(null);

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            form.setValue('q', q);
            if (q != null) {
                applyFilter({ ...form.getValues() });
            }
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [q]);

    React.useEffect(() => {
        if (location?.state?.search) {
            setQ(location.state.search)
            applyFilter({ q: location.state.search });
            ref.current?.focus()
        }
    }, [])
    return (<>
        <div className="app-head-row mb-2">
            <div className="a-top-right-block">
            <button title="Go Back" style={{ color: "#2cc0e4" }} className="btn btn-link" onClick={() => navigate('/Ats')}> <ArrowBack style={{color:"#303030"}}/></button>
                <div className="cus-search">
                    <input ref={ref} type="text" name="" id="" value={q || ''} placeholder="Global Search" onChange={(e) => setQ(e?.target?.value)} />
                </div>
            </div>
        </div>
    </>)
}

const MyList = (props) => {
    const { children } = props;
    return (
        <ListBase perPage={100}  {...props}>
            {/* <Header /> */}
            {/* <br />
            <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="text.primary"> Global Search</Typography>
                </Breadcrumbs>
            </div> */}
            <br />
            <TopMenu />

            <Card>
                {children}
            </Card>
            <Pagination rowsPerPageOptions={[100,200,300,400,500]}/>
        </ListBase>
    )
};





const GetAdminGlobalSearchList = () => {
    const defaultFilterValue = { app_invitation_type: 1, dashboardOption: 1 }
    return (
        // <DashboardOption.Provider value={dashboardOption}>
        <MyList storeKey="GetAdmininboxList30doc" sort={{ field: 'id', order: 'DESC' }} actions={null} filterDefaultValues={defaultFilterValue} filter={defaultFilterValue} exporter={false} title={'Inbox'}>

            <DatagridTable disableSortingAll={true} addressFieldHide={true} rentFieldHide={true} queuedFieldDisplay={false} typeField={true} riFieldHide={true} screeningReport={false}/>
        </MyList>
    )
};

export default GetAdminGlobalSearchList;