import React, { createContext, useEffect } from 'react';
import '../../App.css';
import {
    Datagrid,
    DatagridBody,
    DateField,
    LinearProgress,
    ListBase,
    Pagination, RecordContextProvider, SearchInput, TextField, useGetIdentity, useListContext,
} from 'react-admin';
import { ToggleButton, ToggleButtonGroup, } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import InfoIcon from '@mui/icons-material/Info';
import { Card } from '@mui/material';
import FilterModelAdmin from './FilterModelAdmin';
import { Box, Button as MuiButton } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { TableHead, TableRow, TableCell, Checkbox } from '@mui/material';
import {
    useParams,
    useNavigate
} from "react-router-dom";
import { JSONTree } from 'react-json-tree';
import { DatagridTable } from './GetsubscriberinboxList';
function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    // event.preventDefault();
    console.info('You clicked a breadcrumb.');
}
export const ToogleFilterInboxType = (props) => {

    const { setFilters, filterValues, displayedFilters } = useListContext();
    useEffect(() => {
        if (!filterValues.app_invitation_type) {
            setFilters({ 'app_invitation_type': "1" }, ['app_invitation_type'])
        }
    }, [])

    return (
        <>
            {/* <JSONTree data={filterValues}/> */}
            <ToggleButtonGroup
                sx={{ paddingBottom: 0.5, '& .Mui-selected': { fontWeight: 700 } }}
                size="small"
                value={filterValues.app_invitation_type}
                color="primary"
                exclusive
                onChange={(event: React.MouseEvent<HTMLElement>,
                    newAlignment: string) => {
                    if (newAlignment) {
                        setFilters({ 'app_invitation_type': newAlignment }, ['app_invitation_type'])
                    }
                }}
                aria-label="Inbox"
            >
                <ToggleButton value={"1"} aria-label="Application">
                    Application
                </ToggleButton>
                <ToggleButton value={"2"} aria-label="Docupload">
                    Docupload
                </ToggleButton>
                <ToggleButton value={"3"} aria-label="Docupload">
                    Guest Card
                </ToggleButton>
            </ToggleButtonGroup>
        </>)
};

export const FilterListInbox = (props) => {
    return <>
        {/* <Button size='large' variant='outlined' startIcon={} label='' onClick={() => { }} /> */}
        <IconButton sx={{
            border: '1px solid',
            borderRadius: ' 5px',
            padding: '6px',
            marginLeft: '-10px',
            marginBottom: '4.4px'
        }} size='medium' aria-label="delete">
            <FilterListIcon />
        </IconButton>
    </>
}

export const filters = [
    <ToogleFilterInboxType source="app_invitation_type" alwaysOn />,
    <SearchInput variant="outlined" sx={{ backgroundColor: '#fff' }} placeholder="Search" InputLabelProps={{
        shrink: true,
    }} source="q" alwaysOn />,
];

export default function BasicPopover() {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton color='warning' style={{ color: '#F9BB04' }} size='small' aria-label="info" aria-describedby={id} onClick={handleClick}>
                <InfoIcon style={{ fontSize: '14' }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Typography sx={{ p: 2 }}>This is the Rent to Income Ratio.</Typography>
            </Popover>
        </div>
    );
}

const TopMenu = (props) => {
    return (<>
        <div className="app-head-row mb-2">
            <FilterModelAdmin />
        </div>
    </>)
}

const MyList = (props) => {
    const { children } = props;
    return (
        <ListBase  perPage={100} {...props}>
            {/* <Header /> */}
            <br />
            <div role="presentation" onClick={handleClick}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Typography color="text.primary"> Archive / Cancel </Typography>
                </Breadcrumbs>
            </div>
            <br />
            <Card>
                {children}
            </Card>
            <Pagination rowsPerPageOptions={[100,200,300,400,500]}/>
        </ListBase>
    )
};

// const MyDatagridRow = (props) => {
//     const { record, id, onToggleItem, children, selected, selectable, isRowExpandable, isRowSelectable, expand } = props;
//     const [tempOpen, setTempOpen] = React.useState(false);
//     return (
//         <RecordContextProvider value={record}>
//             <TableRow className={props.className}>
//                 {React.Children.map(children, field => {
//                     if (field)
//                         return (
//                             <TableCell data-label={field?.props?.label || field?.props?.source} key={`${id}-${field?.props?.source || field?.props?.label}`}>
//                                 {field}
//                             </TableCell>
//                         )
//                 })}
//             </TableRow>
//         </RecordContextProvider>
//     )
// };


// const MyDatagridTableHeaderCell = ({ child }) => {
//     const {
//         sort,
//         setSort
//     } = useListContext();
//     const [sortStatus, SetSortStatus] = React.useState(false);
//     const sortBy = child.props.sortBy || child.props.source;
//     return (<>
//         {/* !sort.order?'ASC':sort.order=='ASC'?'DESC':'ASC' */}
//         <Box display={'flex'} alignItems={'center'} justifyContent={'start'}>
//             <span style={{ padding: '0px 2px' }}>
//                 {sortBy === sort.field ? sort.order == "ASC" ? <ArrowUpwardIcon style={{ fontSize: 18 }} /> : <ArrowDownwardIcon style={{ fontSize: 18 }} /> : null}
//             </span>
//             <span style={{ cursor: 'pointer', fontWeight: 'bold', color: sortBy === sort.field ? '#000' : '#909090' }} onClick={() => { sortBy && setSort({ 'field': sortBy, 'order': 'ASC' }) }}>{child.props.label || child.props.source}</span>
//         </Box>

//     </>);
// }


// const MyDatagridHeader = (props) => {
//     const { children, isRowSelectable, isRowExpandable } = props;
//     return (
//         <TableHead sx={{ height: 50, backgroundColor: '#FFFFFF', border: '0.5px solid #E0E0E0' }}>
//             <TableRow>
//                 {isRowSelectable && <TableCell></TableCell>}{/* empty cell to account for the select row checkbox in the body */}
//                 {React.Children.map(children, child => {
//                     if (child)
//                         return (
//                             <TableCell sortDirection="asc" key={child.props.source}>
//                                 <MyDatagridTableHeaderCell child={child} />
//                             </TableCell>
//                         )
//                 })}
//                 {isRowExpandable && <TableCell></TableCell>}
//             </TableRow>
//         </TableHead>
//     )
// };


// const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow {...props} />} />;
// const MyDatagrid = props => <Datagrid {...props} body={<MyDatagridBody {...props} />} />;

export const ArchiveCancelledList = () => {
    const { data: identity } = useGetIdentity();
    const { id } = useParams();
    const defaultFilterValue = { app_invitation_type: 2, subscriberid:id || identity?.subscriberid,archiveCancelApp:true}
    return (
        <MyList sort={{ field: 'id', order: 'DESC' }} actions={null} filter={defaultFilterValue} filterDefaultValues={defaultFilterValue} exporter={false} title={'Inbox'}>
            <DatagridTable/>
        </MyList>
    )
};

// const DatagridTable = (props) => {
//     const { id } = useParams();
//     const {
//         displayedFilters,
//         filterValues,
//         setFilters,
//         hideFilter
//     } = useListContext();
//     const { data, isLoading, isFetching } = useListContext();
//     useEffect(() => {
//         if (isFetching || isLoading)
//             window.document.title = "Loading";
//         else {
//             window.document.title = "Docuverus Dashboard";
//         }
//     }, [isFetching, isLoading])
//     // get user role from  global store

//     // logic for button bg and color as per conditions
//     const bgStyle = (condition: string) => {
//         let style = {};
//         switch (condition) {
//             case 'Invited':
//                 style = { backgroundColor: '#E9E9E9', color: "#909090" };
//                 break;
//             case 'Application In Progress':
//                 style = { backgroundColor: '#DDF8FF', color: "#1CA2C2" };
//                 break;
//             case 'Submitted':
//             case 'Review Pending':
//                 style = { backgroundColor: '#B0EFFF', color: "#127790" };
//                 break;
//             case 'Screening':
//             case 'Completed':
//             case 'Completed-Approved':
//             case 'Verified':
//                 style = { backgroundColor: '#E6FDF0', color: "#46A96F" };
//                 break;
//             case 'Cancelled':
//             case 'Archived':
//             case 'Completed-Unacceptable':

//             case 'Unverifiable':
//                 style = { backgroundColor: '#FFEEEE', color: "#FF4848" };
//                 break;
//             case 'CompletedConditionallyApproved':
//             case 'Further Documentation Required':
//                 style = { backgroundColor: '#FFF8E2', color: "#F9BB04" };
//                 break;
//             default:
//                 style = {};
//         }
//         return style;
//     }

//     return (
//         <>
//             {(isFetching || isLoading) && <LinearProgress />}
//             <MyDatagrid isRowExpandable={true} isRowSelectable={false}
//                 header={<MyDatagridHeader />}
//                 sx={{
//                     '& .RaDatagrid-rowEven': { backgroundColor: "#F9F9F9" },
//                     '& .RaDatagrid-expandIcon': { color: '#fff' },
//                     '& .RaDatagrid-expandIconCell': { backgroundColor: "primary.main" },
//                     '& .RaDatagrid-headerCell': {
//                         fontWeight: 'bold',
//                         fontSize: 12
//                     },
//                     '& .RaDatagrid-table': {
//                         fontSize: 12
//                     },
//                     '& .MuiToggleButton-root.Mui-selected': {
//                         fontWeight: 700
//                     },
//                 }}>
//                 <TextField source="appid"  />
//                 <TextField source="Subscriber"  />
//                 <TextField source="Officeproperty"  />
//                 <TextField source="Browser"  />
//                 <TextField source="Device"  />
//                 <TextField source="OS"  />
//                 <TextField source="Location"  />
//                 <TextField source="Ipaddress"  />
//                 <DateField source="Lastlogindate"  locales="en-US"  />

//             </MyDatagrid></>)
// };


