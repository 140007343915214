import React, { forwardRef } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import "./index.scss";
import ReactDatePicker from "react-datepicker";
import moment from "moment-timezone";
import InputMask from "react-input-mask";
import 'react-datepicker/dist/react-datepicker.css'
import { JSONTree } from "react-json-tree";
const ExampleCustomInput = forwardRef((inputFun, ref) => (
  <InputMask mask={"99/99/9999"} placeholder="mm/dd/yyyy" {...inputFun} inputRef={ref} />
));

const InputDateField = ({ source, notRequired = false, label = '', disabled=false, placeholder = "",onChangeCallback=()=>{}, labelClass = "lable_title", ...props }) => {
  const { control, setValue } = useFormContext();
  const required = !notRequired;
  // Calculate the minimum date as today's date minus 100 years.
  const minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 100);

  return (
    <>
      <Controller
        control={control}
        name={source}
        render={({ field, fieldState }) => {
          const error = fieldState.error !== undefined;
          const { onChange, value, ref, ...rest } = field;
          return (
            <div className="emp-inputs">
              {
                label !== '' &&
                <label htmlFor="select_box" className={`${labelClass} ${error && "text-danger"}`}>
                  {label} {required && <span>&#42;</span>}
                </label>
              }
              <ReactDatePicker
                ref={(elem) => {
                 !props?.stopAutoFocus && elem && ref(elem.input);
                }}
                placeholderText='MM/DD/YYYY'
                disabled={disabled}
                onChange={(date) => {
                  // const newDate = moment(date).utc().set({hour: 0, minute: 0, second: 0});
                  field.onChange(date)
                  onChangeCallback()
                  //moment.utc(date).format('MM/DD/YYYY')
                }}
                selected={field.value ? moment.tz(field.value,"America/Los_Angeles").toDate() : ''}
                showYearDropdown
                dateFormat="MM/dd/yyyy"
                className={`form-control cus-input-control ${required && !field.value && "is-warning"
                  } ${error && "is-invalid"}`}
                customInput={<ExampleCustomInput />}
                timeZone="America/Los_Angeles"
                maxDate={props?.maxDate ? props?.maxDate : null}
                minDate={props?.minDate ? props?.minDate : minDate}
              />
              <div
                className="invalid-feedback"
                style={{ display: "block", fontSize: "70%" }}
              >
                {error !== undefined && fieldState?.error?.message}
              </div>
            </div>
          );
        }}
      />
    </>
  );
};

export default InputDateField;
