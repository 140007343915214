import {
  Datagrid,
  FunctionField,
  ListBase,
  Pagination,
  TextField,
  useListContext,
  useStore,
} from "react-admin";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  Grid,
  Typography,
  TextField as MuiTextField,
  Box,
  Link
} from "@mui/material";
import { debounce } from "lodash";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import ButtonBase from '@mui/material/ButtonBase';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FilterModal from "./FilterModal";
import { ATS_API_URL } from "../../../config";
import InputFileImport from "../../common/InputFileImport";
import DownloadSampleButton from "../../common/DownloadSampleButton";

// Define a filter component for the list view
const MyListToolBar = ({ title }) => {
  const { displayedFilters, filterValues, setFilters, hideFilter, sort } =
    useListContext();
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  // Debounce the search function
  const debouncedSearch = debounce((value) => {
    // Perform your search logic here
    setFilters({ ...filterValues, q_hierarchy: value }, []);
  }, 500); // 500ms debounce delay

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    debouncedSearch(value);
  };

  return (
    <Grid style={{ marginTop: 10, marginBottom: 8 }} container spacing={3}>
      {/* Left side with title */}
      <Grid item xs={12} md={2}>
        <Typography variant="h6">{title}</Typography>
      </Grid>

      {/* Right side with search input, filter button, and add button */}
      <Grid
        item
        xs={12}
        md={10}
        container
        justifyContent="flex-end"
        alignItems="center"
      >
        <div className="group-btn">
          {/* Search Input */}
          <MuiTextField
            placeholder="search"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            defaultValue={filterValues.q}
            onChange={handleSearchChange}
            variant="outlined"
            size="small"
          />

          {/* Filter Button */}
          <FilterModal />
          {/* file export Button */}
          <button
            onClick={() =>
              window.open(
                `${ATS_API_URL}/subscriber/download-xls?downloadType=Hierarchy&subscriberID=${
                  switchSubscriberObj?.value
                }&_sort=${sort?.field || ""}&_order=${
                  sort?.order || ""
                }&_search=${filterValues.q_hierarchy || ""}&_filter=${
                  filterValues?.hierarchyStatus
                    ? filterValues?.hierarchyStatus.toString()
                    : ""
                }`
              )
            }
            type="button"
            className="g-line-btn"
            title="Export all data"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_14375_6193)">
                <path
                  d="M8.66667 8.4987H10.6667L8 11.1654L5.33333 8.4987H7.33333V5.83203H8.66667V8.4987ZM10 3.16536H3.33333V13.832H12.6667V5.83203H10V3.16536ZM2 2.49336C2 2.12803 2.298 1.83203 2.666 1.83203H10.6667L14 5.16536V14.494C14.0006 14.5816 13.984 14.6684 13.951 14.7495C13.9181 14.8306 13.8695 14.9045 13.808 14.9668C13.7466 15.0291 13.6734 15.0788 13.5928 15.1128C13.5121 15.1469 13.4255 15.1648 13.338 15.1654H2.662C2.48692 15.1641 2.31934 15.0941 2.19548 14.9704C2.07161 14.8466 2.0014 14.6791 2 14.504V2.49336Z"
                  fill="#2DC3E8"
                />
              </g>
              <defs>
                <clipPath id="clip0_14375_6193">
                  <rect
                    width="16"
                    height="16"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
          {/* Download Sample Button*/}
          <DownloadSampleButton downloadType={"Hierarchy"} />
          {/* Download Sample Button*/}
          {/* Import CSV Button*/}
          <InputFileImport buttonText={"Import CSV"} importType={"Hierarchy"} />
          {/* Import CSV Button*/}
          {/* Add Hierarchy button */}
          <button
            type="button"
            onClick={() =>
              navigate(`/ats/settings/hierarchyLevel/addNewLevel`, {
                state: { isAdding: true, thirdPartyAppId: 1 },
              })
            }
            className="blue-btn px-3"
          >
            <svg
              className="mr-r10"
              width="10"
              height="11"
              viewBox="0 0 10 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.33337 4.83333V0.833334H5.66671V4.83333H9.66671V6.16667H5.66671V10.1667H4.33337V6.16667H0.333374V4.83333H4.33337Z"
                fill="white"
              />
            </svg>
            Hierarchy Level
          </button>
        </div>
      </Grid>
    </Grid>
  );
};

// Define the list view component using ListBase
const MyList = ({ children, toolBar, ...props }) => {
  return (
    <ListBase
      {...props}
      perPage={100} // Number of items per page
    >
      {toolBar}
      {children}
      <Pagination rowsPerPageOptions={[100, 200, 300, 400, 500]} />
    </ListBase>
  );
};

const ActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#E6FDF0",
  };
  const myTextStyles = {
    color: "var(--Success, #46A96F)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Active</div>
    </div>
  );
};
const DeActiveBtn = () => {
  const myStyles = {
    display: "flex",
    width: "79px",
    padding: "8px 11px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    borderRadius: "4px",
    background: "#FFF1F1",
  };
  const myTextStyles = {
    color: "var(--Success, #FF4848)",
    // textAlign: 'center',
    fontFamily: "Open Sans",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
  };
  return (
    <div style={myStyles}>
      <div style={myTextStyles}>Inactive</div>
    </div>
  );
};

const HierarchyLevelList = () => {
  const navigate = useNavigate();
  const [switchSubscriberObj] = useStore("switchSubscriberName");
  return (
    <MyList
      toolBar={<MyListToolBar title={"Hierarchy Level"} />}
      sort={{ field: "id", order: "DESC" }}
      filter={{ subscriberID: switchSubscriberObj?.value }}
      // filter={{ subscriberID: 10145 }}
    >
      <Datagrid
        sx={{
          "& .RaDatagrid-headerCell": {
            fontWeight: "bold",
            fontSize: 12,
          },
          "& .RaDatagrid-rowEven": { backgroundColor: "#F9F9F9" },
          "& .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(4), & .MuiTableBody-root.datagrid-body.RaDatagrid-tbody tr td.MuiTableCell-root.MuiTableCell-body:nth-child(3)":
            { width: "200px" },
        }}
        bulkActionButtons={false}
      >
        <TextField source="LevelCode" />
        <FunctionField 
        sortByOrder="ASC"
        sortBy={"HierarchyName"}
        label={"Hierarchy Name"}
        render={(record)=>(
          <Link  onClick={() =>
            navigate(
              `/ats/settings/hierarchyLevel/levelDetails/${record?.id}`,
              { state: { isAdding: false, thirdPartyAppId: 1 } }
            )
          } underline="none" style={{cursor:"pointer"}} rel="noopener noreferrer" >{record.HierarchyName} </Link>
        )}/>
        {/* <TextField source="HierarchyName" /> */}
        <FunctionField
          sortByOrder="DESC"
          sortBy="Status"
          label={"Status"}
          render={(record) => (
            <>
              <div className="d-flex align-items-center">
                {record.Active ? <ActiveBtn /> : <DeActiveBtn />}
              </div>
            </>
          )}
        />
        <FunctionField
          label={""}
          render={(record) => (
            <Box display={"flex"}>
              <IconButton
                aria-label="view details"
                color="primary"
                sx={{
                  py: 0,
                }}
                onClick={() =>
                  navigate(
                    `/ats/settings/hierarchyLevel/levelDetails/${record?.id}`,
                    { state: { isAdding: false, thirdPartyAppId: 1 } }
                  )
                }
                className="show_on_hover"
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          )}
        />
        {/* Add more fields as needed */}
      </Datagrid>
    </MyList>
  );
};

export default HierarchyLevelList;
